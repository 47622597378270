import {
    Card,
    CardBody,
    Heading,
    HStack,
    Skeleton,
    Stat,
    StatLabel,
    StatNumber,
    Text,
    useToast,
    VStack,
} from '@chakra-ui/react';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Stack } from '@chakra-ui/react';

import FormatNumber from '../../utils/value/FormatNumber';
import ImageAccount from '../../components/ImageAccount';
import DashboardSvc, { IGetDashboardBalanceResponse } from '../../services/DashboardSvc';
import SocketEvent from 'react-socket-event';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';
import TypeAccountUtil from '../../utils/enums/TypeAccountUtil';

const CardBalance: FC = () => {
    const toast = useToast();
    const loadingRef = useRef(false);
    const sumBalanceRef = useRef(true);
    const [balance, setBalance] = useState<IGetDashboardBalanceResponse[]>([]);
    const [loading, setLoading] = useState(false);
    const [activeBalance, setActiveBalance] = useState(0);
    const [passiveBalance, setPassiveBalance] = useState(0);

    const fetchData = useCallback(async () => {
        try {
            if (loadingRef.current) {
                return;
            }
            sumBalanceRef.current = true;
            loadingRef.current = true;
            setLoading(true);
            const response = await DashboardSvc.getDashboardBalance();
            setBalance(response);
            setActiveBalance(
                response
                    .filter((it) => it.type === 'CCOR')
                    .reduce((prev, cur) => {
                        return prev + cur.value;
                    }, 0)
            );
            const passive = response
                .filter((it) => it.type === 'CCRE')
                .reduce((prev, cur) => {
                    return prev + cur.value;
                }, 0);
            setPassiveBalance(passive < 0 ? passive * -1 : passive);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toast({
                description: error?.message,
                duration: 5000,
                status: 'error',
            });
        } finally {
            loadingRef.current = false;
        }
    }, [toast]);

    useEffect(() => {
        const remove = SocketEvent.on(
            'UPDATE_BALANCE_VALUE',
            ({ value }: { value: number }) => {
                if (sumBalanceRef.current) {
                    setActiveBalance((val) => val + value);
                    sumBalanceRef.current = false;
                }
            },
            'DASHBOARD_CARD_BALANCE'
        );
        return remove;
    }, []);

    useEffect(() => {
        fetchData();
        const remove = SocketEvent.on('REFRESH_DASHBOARD', () => fetchData(), 'DASHBOARD_CARD_BALANCE');
        return remove;
    }, [fetchData]);

    return (
        <VStack padding={15} alignItems="flex-start">
            <Heading as="h2" size="md" marginBottom={3}>
                Balanço das contas
            </Heading>
            <HStack padding={15} spacing={10} flexWrap="wrap">
                <HStack>
                    <Stat>
                        <StatLabel>Ativo Financeiro Atual</StatLabel>
                        <StatNumber>
                            <Text color="green.500" fontSize="2xl">
                                {FormatNumber.moneyInteger(activeBalance)}
                            </Text>
                        </StatNumber>
                    </Stat>
                </HStack>
                <HStack>
                    <Stat>
                        <StatLabel>Passivo Financeiro Atual</StatLabel>
                        <StatNumber>
                            <Text color="red.400" fontSize="2xl">
                                {FormatNumber.moneyInteger(passiveBalance)}
                            </Text>
                        </StatNumber>
                    </Stat>
                </HStack>
                <HStack>
                    <ResponsiveContainer width={170} height={75}>
                        <PieChart width={170} height={75}>
                            <Pie
                                data={[
                                    { name: 'Ativo', value: activeBalance },
                                    { name: 'Passivo', value: passiveBalance },
                                ]}
                                cx="50%"
                                cy="50%"
                                labelLine={false}
                                label={renderCustomizedLabel}
                                outerRadius={35}
                                fill="#8884d8"
                                dataKey="value"
                            >
                                <Cell key="active" fill="#38A169" />
                                <Cell key="passive" fill="#F56565" />
                            </Pie>
                        </PieChart>
                    </ResponsiveContainer>
                </HStack>
            </HStack>
            <HStack flexWrap="wrap">
                {loading
                    ? [1, 2, 3, 4].map((val) => <Skeleton key={val} height="170px" width="300px" />)
                    : balance.map((it) => (
                          <CardBalanceBase
                              key={it.id}
                              title={it.name}
                              description={TypeAccountUtil.description(it.type)}
                              urlIcon={it.urlIcon}
                              value={it.value}
                          />
                      ))}
            </HStack>
        </VStack>
    );
};

interface ICardBalanceBaseProps {
    title: string;
    urlIcon: string;
    description: string;
    value: number;
}

const CardBalanceBase: FC<ICardBalanceBaseProps> = ({ title, urlIcon, description, value }) => {
    return (
        <Card minW="xs">
            <CardBody>
                <Stack mt="2" spacing="3">
                    <HStack>
                        <ImageAccount size={50} urlIcon={urlIcon} />
                        <Heading size="md">{title}</Heading>
                    </HStack>
                    <Text>{description}</Text>
                    <Text color={value >= 0 ? 'green.500' : 'red.400'} fontSize="2xl">
                        {FormatNumber.moneyInteger(value)}
                    </Text>
                </Stack>
            </CardBody>
        </Card>
    );
};

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 1.2;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
};

export default CardBalance;
