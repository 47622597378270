import {
    Flex,
    Box,
    FormControl,
    FormLabel,
    Input,
    Checkbox,
    Stack,
    Link,
    Button,
    Heading,
    Text,
    useColorModeValue,
    useToast,
    ToastId,
} from '@chakra-ui/react';
import { FaGoogle } from 'react-icons/fa';
import { GoogleLogin } from 'react-google-login';
import { gapi } from 'gapi-script';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import AuthSvc from '../../services/AuthSvc';
import Session from '../../utils/session/Session';

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID ?? '';

export default function LoginPage() {
    const toast = useToast();
    const toastIdRef = useRef<ToastId>();
    let navigate = useNavigate();
    const [loadingAuthGoogle, setLoadingAuthGoogle] = useState(false);

    useEffect(() => {
        const initClient = () => {
            gapi.client.init({
                clientId: clientId,
                scope: '',
            });
        };
        gapi.load('client:auth2', initClient);
    });

    async function onSuccess(res: any) {
        try {
            const response = await AuthSvc.googleLogin({ googleToken: res?.tokenId ?? '' });
            Session.set(response, true);
            setLoadingAuthGoogle(false);
            navigate('/', {
                replace: true,
            });
        } catch (error) {
            toastIdRef.current = toast({ description: error?.message ?? 'Erro ao tentar realizar login', duration: 5000, status: 'error' });
            setLoadingAuthGoogle(false);
        }
    }

    function onFailure(err: any) {
        console.log('failed:', err);
        toastIdRef.current = toast({ description: err?.message ?? 'Erro ao tentar realizar login', duration: 5000, status: 'error' });
        setLoadingAuthGoogle(false);
    }

    return (
        <Flex minH={'100vh'} align={'center'} justify={'center'} bg={useColorModeValue('gray.50', 'gray.800')}>
            <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
                <Stack align={'center'}>
                    <Heading fontSize={'4xl'}>Acesse sua conta</Heading>
                    <Text fontSize={'lg'} color={'gray.600'}>
                        Somente se você foi convidado ✌️
                    </Text>
                </Stack>
                <Box rounded={'lg'} bg={useColorModeValue('white', 'gray.700')} boxShadow={'lg'} p={8}>
                    <Stack spacing={4}>
                        <FormControl id="email">
                            <FormLabel>Email address</FormLabel>
                            <Input type="email" />
                        </FormControl>
                        <FormControl id="password">
                            <FormLabel>Password</FormLabel>
                            <Input type="password" />
                        </FormControl>
                        <Stack spacing={10}>
                            <Stack direction={{ base: 'column', sm: 'row' }} align={'start'} justify={'space-between'}>
                                <Checkbox>Lembrar acesso</Checkbox>
                                <Link color={'blue.400'}>Esqueceu a senha?</Link>
                            </Stack>
                            <Button
                                bg={'blue.400'}
                                color={'white'}
                                _hover={{
                                    bg: 'blue.500',
                                }}
                            >
                                Entrar
                            </Button>
                            <GoogleLogin
                                clientId={clientId}
                                onSuccess={onSuccess}
                                onFailure={onFailure}
                                cookiePolicy={'single_host_origin'}
                                isSignedIn={false}
                                onRequest={() => {
                                    setLoadingAuthGoogle(true);
                                }}
                                render={(props) => (
                                    <Button
                                        {...props}
                                        colorScheme="red"
                                        leftIcon={<FaGoogle />}
                                        isLoading={loadingAuthGoogle}
                                        loadingText="verificando..."
                                    >
                                        Entrar com Google
                                    </Button>
                                )}
                            />
                        </Stack>
                    </Stack>
                </Box>
            </Stack>
        </Flex>
    );
}
