import Api from '../api/Api';
import { ISession } from '../utils/session/Session';

export interface IGoogleLoginRequest {
    googleToken: string;
}

export interface IGoogleLoginResponse extends ISession {}

async function googleLogin(param: IGoogleLoginRequest): Promise<IGoogleLoginResponse> {
    const response = await Api.post('/v1/auth/googleLogin', param);
    return response.data;
}

export default {
    googleLogin,
};
