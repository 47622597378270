import {
    Heading,
    HStack,
    Skeleton,
    Stat,
    StatLabel,
    StatNumber,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useToast,
    VStack,
} from '@chakra-ui/react';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import axios from 'axios';

import FormatNumber from '../../utils/value/FormatNumber';
import SocketEvent from 'react-socket-event';

interface IInvestmentResponse {
    totalInvestido: number;
    totalAtual: number;
    totalValorizacao: number;
    ativos: Array<{
        ativo: string;
        quantidade: number;
        valorMedio: number;
        taxas: number;
        totalCompra: number;
        valorUnitAtual: number;
        totalAtual: number;
    }>;
}

const CardInvestment: FC = () => {
    const toast = useToast();
    const loadingRef = useRef(false);
    const [investment, setInvestment] = useState<IInvestmentResponse | null>(null);
    const [loading, setLoading] = useState(false);

    const fetchData = useCallback(async () => {
        try {
            if (loadingRef.current) {
                return;
            }
            loadingRef.current = true;
            setLoading(true);
            const response = await axios.get<IInvestmentResponse>(process.env.REACT_APP_API_INVESTIMENT ?? '');
            setInvestment(response.data);
            setLoading(false);
            const totalAtual = response.data.totalAtual;
            if (totalAtual !== undefined && totalAtual !== null) {
                SocketEvent.emit('UPDATE_BALANCE_VALUE', { value: FormatNumber.decimalToInteger(totalAtual) });
            }
        } catch (error) {
            console.log(error);
            setLoading(false);
            toast({
                description: error?.message,
                duration: 5000,
                status: 'error',
            });
        } finally {
            loadingRef.current = false;
        }
    }, [toast]);

    useEffect(() => {
        fetchData();
        const remove = SocketEvent.on('REFRESH_DASHBOARD', () => fetchData(), 'DASHBOARD_CARD_BALANCE');
        return remove;
    }, [fetchData]);

    return (
        <VStack padding={15} alignItems="flex-start">
            <Heading as="h2" size="md" marginBottom={3}>
                Investimentos
            </Heading>
            {loading ? (
                <VStack alignItems="flex-start">
                    <HStack>
                        {[1, 2, 3].map((val) => (
                            <Skeleton key={val} height="70px" width="200px" />
                        ))}
                    </HStack>
                    <VStack>
                        {[1, 2, 3, 4].map((val) => (
                            <Skeleton key={val} height="40px" width="1200px" />
                        ))}
                    </VStack>
                </VStack>
            ) : (
                <>
                    <HStack padding={15} spacing={10} flexWrap="wrap">
                        <HStack>
                            <Stat>
                                <StatLabel>Ativo Financeiro Atual</StatLabel>
                                <StatNumber>
                                    <Text color="green.500" fontSize="2xl">
                                        {FormatNumber.money(investment?.totalAtual ?? 0)}
                                    </Text>
                                </StatNumber>
                            </Stat>
                        </HStack>
                        <HStack>
                            <Stat>
                                <StatLabel>Ativo Financeiro Investido</StatLabel>
                                <StatNumber>
                                    <Text color="green.500" fontSize="2xl">
                                        {FormatNumber.money(investment?.totalInvestido ?? 0)}
                                    </Text>
                                </StatNumber>
                            </Stat>
                        </HStack>
                        <HStack>
                            <Stat>
                                <StatLabel>Valorização</StatLabel>
                                <StatNumber>
                                    <Text
                                        color={(investment?.totalValorizacao ?? 0) >= 0 ? 'green.500' : 'red.400'}
                                        fontSize="2xl"
                                    >
                                        {FormatNumber.money(investment?.totalValorizacao ?? 0)} |{' '}
                                        {FormatNumber.decimal(
                                            ((investment?.totalValorizacao ?? 0) / (investment?.totalInvestido ?? 0)) *
                                                100,
                                            2
                                        )}
                                        %
                                    </Text>
                                </StatNumber>
                            </Stat>
                        </HStack>
                    </HStack>
                    <HStack>
                        <TableContainer>
                            <Table size="sm">
                                <Thead>
                                    <Tr>
                                        <Th>Ativo</Th>
                                        <Th>Quantidade</Th>
                                        <Th>Valor Médio</Th>
                                        <Th>Total de Taxas</Th>
                                        <Th>Total de Compra</Th>
                                        <Th>Valor Unitário Atual</Th>
                                        <Th>Valor Total Atual</Th>
                                        <Th>Valorização</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {investment?.ativos?.map((item) => (
                                        <Tr key={item.ativo}>
                                            <Td fontWeight="bold">{item.ativo}</Td>
                                            <Td isNumeric>{item.quantidade}</Td>
                                            <Td isNumeric>{FormatNumber.money(item.valorMedio)}</Td>
                                            <Td isNumeric>{FormatNumber.money(item.taxas)}</Td>
                                            <Td isNumeric>{FormatNumber.money(item.totalCompra)}</Td>
                                            <Td isNumeric>{FormatNumber.money(item.valorUnitAtual)}</Td>
                                            <Td isNumeric>{FormatNumber.money(item.totalAtual)}</Td>
                                            <Td
                                                isNumeric
                                                fontWeight="bold"
                                                color={
                                                    (item.totalAtual - item.totalCompra ?? 0) >= 0
                                                        ? 'green.500'
                                                        : 'red.400'
                                                }
                                            >
                                                {FormatNumber.money(item.totalAtual - item.totalCompra)} |{' '}
                                                {FormatNumber.decimal(
                                                    ((item.totalAtual - item.totalCompra ?? 0) /
                                                        (item?.totalCompra ?? 0)) *
                                                        100,
                                                    2
                                                )}
                                                %
                                            </Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        </TableContainer>
                    </HStack>
                </>
            )}
        </VStack>
    );
};

export default CardInvestment;
