import {
    Box,
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    HStack,
    Input,
    InputGroup,
    InputLeftAddon,
    InputLeftElement,
    InputRightElement,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Skeleton,
    Spinner,
    Text,
    useToast,
    VStack,
} from '@chakra-ui/react';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Stack } from '@chakra-ui/react';
import * as yup from 'yup';

import FlowsSvc, { TypeOperation, IFlow } from '../../services/FlowsSvc';
import AccountsSvc, { IAccount } from '../../services/AccountsSvc';
import GroupsSvc, { IGroup } from '../../services/GroupsSvc';
import SubGroupsSvc, { ISubGroup } from '../../services/SubGroupsSvc';
import NumberUtils from '../../utils/value/NumberUtils';
import { ChevronRightIcon, ChevronLeftIcon } from '@chakra-ui/icons';
import ImageAccount from '../../components/ImageAccount';

interface IEditFlowModalProps {
    isOpen: boolean;
    onClose: () => void;
    flow: IFlow | null;
    fetchFlows: (reset: boolean) => void;
}

function numberToPositive(value?: number) {
    if (value === null || value === undefined) {
        return 0;
    }
    return value < 0 ? value * -1 : value;
}

function getAccountFilter() {
    const valueString = sessionStorage.getItem('@controle-financeiro:account');
    if (valueString) {
        return JSON.parse(valueString);
    }
    return null;
}

function getLastFlowDate() {
    const valueString = sessionStorage.getItem('@controle-financeiro:last-flow-date');
    if (valueString) {
        return valueString;
    }
    return '';
}

const EditFlowModal: FC<IEditFlowModalProps> = ({ isOpen, onClose, flow, fetchFlows }) => {
    const toast = useToast();
    const inputRef = useRef<any>(null);
    const [typeOperation, setTypeOperation] = useState<TypeOperation>(flow?.type ?? 'O');
    const [flowDate, setFlowDate] = useState(flow?.flowDate ? flow.flowDate.substring(0, 10) : getLastFlowDate());
    const [description, setDescription] = useState(flow?.description ?? '');
    const [operationValue, setOperationValue] = useState(numberToPositive(flow?.value));
    const [erros, setErros] = useState<any>({});
    const [loading, setLoading] = useState(false);
    const [account, setAccount] = useState<IAccount | null>(flow?.account ?? getAccountFilter());
    const [targetAccount, setTargetAccount] = useState<IAccount | null>(flow?.targetAccount ?? null);
    const [group, setGroup] = useState<IGroup | null>(flow?.group ?? null);
    const [subGroup, setSubGroup] = useState<ISubGroup | null>(flow?.subGroup ?? null);
    const [accounts, setAccounts] = useState<IAccount[]>([]);
    const [groups, setGroups] = useState<IGroup[]>([]);
    const [subGroups, setSubGroups] = useState<ISubGroup[]>([]);

    const [loadingAccount, setLoadingAccount] = useState(false);
    const [loadingGroup, setLoadingGroup] = useState(false);
    const [loadingSubGroup, setLoadingSubGroup] = useState(false);

    async function createFlow() {
        try {
            setErros({});
            const schema = yup.object().shape({
                account: yup.object().required('Conta não foi informada'),
                operationValue: yup.number().required('Valor da operação é obrigatório'),
                flowDate: yup.string().required('Data da operação não foi informada'),
            });
            schema.validateSync({ operationValue, flowDate, account }, { abortEarly: false });
            setLoading(true);

            sessionStorage.setItem('@controle-financeiro:last-flow-date', flowDate);

            if (typeOperation === 'TO') {
                const schema = yup.object().shape({
                    targetAccount: yup.object().required('Conta de Destino não foi informada'),
                });
                schema.validateSync({ targetAccount }, { abortEarly: false });
                setLoading(true);

                await FlowsSvc.createFlow({
                    description,
                    value: operationValue,
                    accountId: account?.id ?? '',
                    targetAccountId: targetAccount?.id ?? '',
                    type: typeOperation,
                    flowDate,
                });
            } else {
                const schema = yup.object().shape({
                    group: yup.object().required('Grupo não foi informado'),
                    subGroup: yup.object().required('Sub-Grupo não foi informado'),
                });
                schema.validateSync({ group, subGroup }, { abortEarly: false });
                setLoading(true);

                await FlowsSvc.createFlow({
                    description,
                    value: operationValue,
                    accountId: account?.id ?? '',
                    groupId: group?.id ?? '',
                    subGroupId: subGroup?.id ?? '',
                    type: typeOperation,
                    flowDate,
                });
            }

            setLoading(false);
            fetchFlows(true);
            onClose();
        } catch (error) {
            setLoading(false);
            if (error?.description === 'ValidationError') {
                setErros(
                    (error as yup.ValidationError).inner.reduce(
                        (acc: Object, cur: yup.ValidationError) => ({
                            ...acc,
                            [cur.path as string]: cur.errors[0],
                        }),
                        {}
                    )
                );
            } else {
                toast({
                    description: error?.message ?? 'Erro ao tentar salvar operação',
                    duration: 5000,
                    status: 'error',
                });
            }
        }
    }

    async function updateFlow() {
        try {
            setErros({});
            const schema = yup.object().shape({
                account: yup.object().required('Conta não foi informada'),
                operationValue: yup.number().required('Valor da operação é obrigatório'),
                flowDate: yup.string().required('Data da operação não foi informada'),
            });
            schema.validateSync({ operationValue, flowDate, account }, { abortEarly: false });
            setLoading(true);

            if (typeOperation === 'TO') {
                const schema = yup.object().shape({
                    targetAccount: yup.object().required('Conta de Destino não foi informada'),
                });
                schema.validateSync({ targetAccount }, { abortEarly: false });
                setLoading(true);

                await FlowsSvc.updateFlow(flow?.id ?? '', {
                    description,
                    value: operationValue,
                    accountId: account?.id ?? '',
                    targetAccountId: targetAccount?.id ?? '',
                    type: typeOperation,
                    flowDate,
                });
            } else {
                const schema = yup.object().shape({
                    group: yup.object().required('Grupo não foi informado'),
                    subGroup: yup.object().required('Sub-Grupo não foi informado'),
                });
                schema.validateSync({ group, subGroup }, { abortEarly: false });
                setLoading(true);

                await FlowsSvc.updateFlow(flow?.id ?? '', {
                    description,
                    value: operationValue,
                    accountId: account?.id ?? '',
                    groupId: group?.id ?? '',
                    subGroupId: subGroup?.id ?? '',
                    type: typeOperation,
                    flowDate,
                });
            }

            setLoading(false);
            fetchFlows(true);
            onClose();
        } catch (error) {
            setLoading(false);
            if (error?.description === 'ValidationError') {
                setErros(
                    (error as yup.ValidationError).inner.reduce(
                        (acc: Object, cur: yup.ValidationError) => ({
                            ...acc,
                            [cur.path as string]: cur.errors[0],
                        }),
                        {}
                    )
                );
            } else {
                toast({
                    description: error?.message ?? 'Erro ao tentar salvar operação',
                    duration: 5000,
                    status: 'error',
                });
            }
        }
    }

    function addOneDay(value: number) {
        if (!flowDate) {
            return;
        }
        let data = new Date(`${flowDate}T12:00:00.000Z`);
        data.setDate(data.getDate() + value);
        let ano = data.getFullYear();
        let mes = ('0' + (data.getMonth() + 1)).slice(-2);
        let dia = ('0' + data.getDate()).slice(-2);
        setFlowDate(`${ano}-${mes}-${dia}`);
    }

    const fetchSubGroups = useCallback(
        async (groupId: string) => {
            try {
                setLoadingSubGroup(true);
                const response = await SubGroupsSvc.getSubGroups(groupId, {
                    order: 'name',
                });
                setSubGroups(response.subGroups);
                setLoadingSubGroup(false);
            } catch (error) {
                setLoadingSubGroup(false);
                toast({
                    description: error?.message,
                    duration: 5000,
                    status: 'error',
                });
            }
        },
        [toast]
    );

    useEffect(() => {
        if (flow?.groupId) {
            fetchSubGroups(flow?.groupId);
        }
    }, [fetchSubGroups, flow]);

    const fetchGroups = useCallback(async () => {
        try {
            setLoadingGroup(true);
            const response = await GroupsSvc.getGroups({
                page: 1,
                limit: 1000,
                order: 'name',
            });
            setGroups(response.result);
            setLoadingGroup(false);
        } catch (error) {
            setLoadingGroup(false);
            toast({
                description: error?.message,
                duration: 5000,
                status: 'error',
            });
        }
    }, [toast]);

    useEffect(() => {
        fetchGroups();
    }, [fetchGroups]);

    const fetchAccounts = useCallback(async () => {
        try {
            setLoadingAccount(true);
            const response = await AccountsSvc.getAccounts({
                page: 1,
                limit: 100,
                order: 'name',
            });
            setAccounts(response.result);
            setLoadingAccount(false);
        } catch (error) {
            setLoadingAccount(false);
            toast({
                description: error?.message,
                duration: 5000,
                status: 'error',
            });
        }
    }, [toast]);

    useEffect(() => {
        fetchAccounts();
    }, [fetchAccounts]);

    return (
        <Modal onClose={onClose} isOpen={isOpen} isCentered size="6xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{flow ? 'Editar operação' : 'Nova operação'}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <VStack alignItems="flex-start">
                        {loading && <Spinner />}

                        <HStack>
                            <HStack width="100%">
                                <FormControl isRequired isInvalid={!!erros['flowDate']}>
                                    <FormLabel>Data da operação</FormLabel>
                                    <InputGroup>
                                        <Input
                                            value={flowDate}
                                            onChange={(e) => setFlowDate(e.target.value)}
                                            type="date"
                                        />
                                        <InputLeftElement width="3rem">
                                            <Button h="2.1rem" size="sm" onClick={() => addOneDay(-1)} variant="link">
                                                <ChevronLeftIcon fontSize={20} />
                                            </Button>
                                        </InputLeftElement>
                                        <InputRightElement width="3rem">
                                            <Button h="2.1rem" size="sm" onClick={() => addOneDay(1)} variant="link">
                                                <ChevronRightIcon fontSize={20} />
                                            </Button>
                                        </InputRightElement>

                                        {!!erros['flowDate'] && (
                                            <FormErrorMessage>{erros['flowDate']}</FormErrorMessage>
                                        )}
                                    </InputGroup>
                                </FormControl>
                            </HStack>

                            <HStack width="100%">
                                <FormControl isRequired isInvalid={!!erros['operationValue']}>
                                    <FormLabel>Valor da operação</FormLabel>
                                    <InputGroup>
                                        <InputLeftAddon children="R$" />
                                        <Input
                                            type="text"
                                            inputMode="numeric"
                                            value={NumberUtils.formatKeyMoney(operationValue)}
                                            onChange={() => {}}
                                            onKeyDown={(e) => {
                                                const val = NumberUtils.inputKeyMoney(
                                                    operationValue,
                                                    e.nativeEvent.key
                                                );
                                                setOperationValue(val);
                                            }}
                                            onPaste={(e) => {
                                                const val = NumberUtils.formatValueOnPaste(
                                                    e.clipboardData.getData('Text')
                                                );
                                                setOperationValue(val);
                                            }}
                                            ref={inputRef}
                                        />
                                    </InputGroup>
                                    {!!erros['operationValue'] && (
                                        <FormErrorMessage>{erros['operationValue']}</FormErrorMessage>
                                    )}
                                </FormControl>
                            </HStack>
                            <HStack width="100%">
                                <FormControl isInvalid={!!erros['description']}>
                                    <FormLabel>Descrição</FormLabel>
                                    <Input value={description} onChange={(e) => setDescription(e.target.value)} />
                                    {!!erros['description'] && (
                                        <FormErrorMessage>{erros['description']}</FormErrorMessage>
                                    )}
                                </FormControl>
                            </HStack>
                        </HStack>

                        <HStack pt={3}>
                            <VStack alignItems="flex-start" w="12vw">
                                <Text color="gray.400" fontSize={12} fontWeight="bold">
                                    OPERAÇÃO
                                </Text>
                                <VStack height="60vh" overflowY="auto" p={2} width="100%">
                                    <Stack width="100%">
                                        <Box
                                            cursor="pointer"
                                            borderWidth="1px"
                                            borderRadius="md"
                                            boxShadow="md"
                                            _hover={{
                                                boxShadow: 'outline',
                                            }}
                                            px={5}
                                            py={3}
                                            bg={typeOperation === 'O' ? 'red.600' : undefined}
                                            onClick={() => setTypeOperation('O')}
                                        >
                                            <Text>SAÍDA</Text>
                                        </Box>
                                    </Stack>
                                    <Stack width="100%">
                                        <Box
                                            cursor="pointer"
                                            borderWidth="1px"
                                            borderRadius="md"
                                            boxShadow="md"
                                            _hover={{
                                                boxShadow: 'outline',
                                            }}
                                            px={5}
                                            py={3}
                                            bg={typeOperation === 'I' ? 'green.600' : undefined}
                                            onClick={() => setTypeOperation('I')}
                                        >
                                            <Text>ENTRADA</Text>
                                        </Box>
                                    </Stack>
                                    <Stack width="100%">
                                        <Box
                                            cursor="pointer"
                                            borderWidth="1px"
                                            borderRadius="md"
                                            boxShadow="md"
                                            _hover={{
                                                boxShadow: 'outline',
                                            }}
                                            px={5}
                                            py={3}
                                            bg={typeOperation === 'TO' ? 'blue.600' : undefined}
                                            onClick={() => setTypeOperation('TO')}
                                        >
                                            <Text>TRANSFERÊNCIA</Text>
                                        </Box>
                                    </Stack>
                                </VStack>
                            </VStack>

                            <VStack alignItems="flex-start" w="12vw">
                                <Text color="gray.400" fontSize={12} fontWeight="bold">
                                    CONTA
                                </Text>
                                <VStack height="60vh" overflowY="auto" p={2} width="100%">
                                    {loadingAccount ? (
                                        <Stack>
                                            {[1, 2, 3, 4, 5, 6, 7].map((i) => (
                                                <Skeleton key={i} height="50px" w="10vw" />
                                            ))}
                                        </Stack>
                                    ) : (
                                        accounts.map((it) => (
                                            <Stack key={it.id} width="100%">
                                                <Box
                                                    cursor="pointer"
                                                    borderWidth="1px"
                                                    borderRadius="md"
                                                    boxShadow="md"
                                                    _hover={{
                                                        boxShadow: 'outline',
                                                    }}
                                                    px={5}
                                                    py={3}
                                                    bg={account?.id === it.id ? 'blue.600' : undefined}
                                                    onClick={() => setAccount(it)}
                                                >
                                                    <HStack>
                                                        <ImageAccount size={30} urlIcon={it?.urlIcon} />
                                                        <Text>{it.name}</Text>
                                                    </HStack>
                                                </Box>
                                            </Stack>
                                        ))
                                    )}
                                </VStack>
                            </VStack>

                            {typeOperation === 'TO' ? (
                                <VStack alignItems="flex-start" w="12vw">
                                    <Text color="gray.400" fontSize={12} fontWeight="bold">
                                        CONTA DESTINO
                                    </Text>
                                    <VStack height="60vh" overflowY="auto" p={2} width="100%">
                                        {loadingAccount ? (
                                            <Stack>
                                                {[1, 2, 3, 4, 5, 6, 7].map((i) => (
                                                    <Skeleton key={i} height="50px" w="10vw" />
                                                ))}
                                            </Stack>
                                        ) : (
                                            accounts.map((it) => (
                                                <Stack key={it.id} width="100%">
                                                    <Box
                                                        cursor="pointer"
                                                        borderWidth="1px"
                                                        borderRadius="md"
                                                        boxShadow="md"
                                                        _hover={{
                                                            boxShadow: 'outline',
                                                        }}
                                                        px={5}
                                                        py={3}
                                                        bg={targetAccount?.id === it.id ? 'blue.600' : undefined}
                                                        onClick={() => {
                                                            setTargetAccount(it);
                                                            inputRef.current?.focus();
                                                        }}
                                                    >
                                                        <HStack>
                                                            <ImageAccount size={30} urlIcon={it?.urlIcon} />
                                                            <Text>{it.name}</Text>
                                                        </HStack>
                                                    </Box>
                                                </Stack>
                                            ))
                                        )}
                                    </VStack>
                                </VStack>
                            ) : (
                                <>
                                    <VStack alignItems="flex-start" w="12vw">
                                        <Text color="gray.400" fontSize={12} fontWeight="bold">
                                            GRUPOS
                                        </Text>
                                        <VStack height="60vh" overflowY="auto" p={2} width="100%">
                                            {loadingGroup ? (
                                                <Stack>
                                                    {[1, 2, 3, 4, 5, 6, 7].map((i) => (
                                                        <Skeleton key={i} height="50px" w="10vw" />
                                                    ))}
                                                </Stack>
                                            ) : (
                                                groups.map((it) => (
                                                    <Stack key={it.id} width="100%">
                                                        <Box
                                                            cursor="pointer"
                                                            borderWidth="1px"
                                                            borderRadius="md"
                                                            boxShadow="md"
                                                            _hover={{
                                                                boxShadow: 'outline',
                                                            }}
                                                            px={5}
                                                            py={2}
                                                            bg={group?.id === it.id ? 'blue.600' : undefined}
                                                            onClick={() => {
                                                                setGroup(it);
                                                                setSubGroup(null);
                                                                fetchSubGroups(it.id);
                                                            }}
                                                        >
                                                            <Text>{it.name}</Text>
                                                        </Box>
                                                    </Stack>
                                                ))
                                            )}
                                        </VStack>
                                    </VStack>

                                    <VStack alignItems="flex-start" w="12vw">
                                        <Text color="gray.400" fontSize={12} fontWeight="bold">
                                            SUB-GRUPOS
                                        </Text>
                                        <VStack height="60vh" overflowY="auto" p={2} width="100%">
                                            {loadingSubGroup ? (
                                                <Stack>
                                                    {[1, 2, 3, 4, 5, 6, 7].map((i) => (
                                                        <Skeleton key={i} height="50px" w="10vw" />
                                                    ))}
                                                </Stack>
                                            ) : (
                                                subGroups.map((it) => (
                                                    <Stack key={it.id} width="100%">
                                                        <Box
                                                            cursor="pointer"
                                                            borderWidth="1px"
                                                            borderRadius="md"
                                                            boxShadow="md"
                                                            _hover={{
                                                                boxShadow: 'outline',
                                                            }}
                                                            px={5}
                                                            py={2}
                                                            bg={subGroup?.id === it.id ? 'blue.600' : undefined}
                                                            onClick={() => {
                                                                setSubGroup(it);
                                                                inputRef.current?.focus();
                                                            }}
                                                        >
                                                            <Text>{it.name}</Text>
                                                        </Box>
                                                    </Stack>
                                                ))
                                            )}
                                        </VStack>
                                    </VStack>
                                </>
                            )}
                        </HStack>
                    </VStack>
                </ModalBody>
                <ModalFooter justifyContent="space-between">
                    {!!flow && <Text fontSize={12}>Criado em: {new Date(flow.createdAt).toLocaleString()}</Text>}
                    <div />
                    <Button onClick={flow ? updateFlow : createFlow} colorScheme="blue">
                        Salvar
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default EditFlowModal;
