import Api, { IPaginateRequest, IPaginateResponse } from '../api/Api';
import { ISubGroup } from './SubGroupsSvc';

export interface IGroup {
    id: string;
    name: string;
    subGroups?: ISubGroup[];
    createdAt: Date;
    createdBy: string;
    updatedAt: Date;
    updatedBy: string;
    deletedAt: Date | null;
}

export interface IGetGroupsRequest extends IPaginateRequest {
    search?: string | null;
    deleted?: boolean;
    order?: string | null;
}

export interface IGetGroupsResponse extends IPaginateResponse<IGroup> {}

export interface IDeleteGroupResponse {
    message: string;
}

export interface IReactivateGroupResponse {
    message: string;
}

export interface ICreateGroupRequest {
    name: string;
}

export interface ICreateGroupResponse extends IGroup {}

export interface IUpdateGroupRequest {
    name: string;
}

export interface IUpdateGroupResponse extends IGroup {}

async function getGroups(param: IGetGroupsRequest): Promise<IGetGroupsResponse> {
    const response = await Api.get('/v1/groups', param);
    return response.data;
}

async function getGroup(id: string): Promise<IGroup> {
    const response = await Api.get(`/v1/groups/${id}`);
    return response.data;
}

async function createGroup(param: ICreateGroupRequest): Promise<ICreateGroupResponse> {
    const response = await Api.post('/v1/groups', param);
    return response.data;
}

async function updateGroup(id: string, param: IUpdateGroupRequest): Promise<IUpdateGroupResponse> {
    const response = await Api.put(`/v1/groups/${id}`, param);
    return response.data;
}

async function deleteGroup(id: string): Promise<IDeleteGroupResponse> {
    const response = await Api.delete(`/v1/groups/${id}`);
    return response.data;
}

async function reactivateGroup(id: string): Promise<IReactivateGroupResponse> {
    const response = await Api.post(`/v1/groups/reactivate/${id}`);
    return response.data;
}

export default {
    getGroups,
    getGroup,
    createGroup,
    updateGroup,
    deleteGroup,
    reactivateGroup,
};
