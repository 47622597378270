import {
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    HStack,
    Input,
    InputGroup,
    InputLeftAddon,
    Select,
    Spinner,
    useToast,
    VStack,
} from '@chakra-ui/react';
import { FC, useEffect, useState, useCallback, useRef } from 'react';
import { Stack } from '@chakra-ui/react';
import * as yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';

import AccountsSvc, { IAccount, TypeAccount } from '../../services/AccountsSvc';
import FormatNumber from '../../utils/value/FormatNumber';
import BreadcrumbCustom from '../../components/BreadcrumbCustom';
import ImageAccount from '../../components/ImageAccount';
import TypeAccountUtil from '../../utils/enums/TypeAccountUtil';

const EditAccountPage: FC = () => {
    const toast = useToast();
    const navigate = useNavigate();
    const { id } = useParams();
    const isNewRef = useRef(id === 'new');
    const idRef = useRef(id);
    const loadingRef = useRef(false);

    const [name, setName] = useState('');
    const [urlIcon, setUrlIcon] = useState('');
    const [typeAccount, setTypeAccount] = useState<TypeAccount | ''>('');
    const [initialValue, setInitialValue] = useState<number>(0);
    const [initialValueText, setInitialValueText] = useState('');
    const [erros, setErros] = useState<any>({});
    const [loading, setLoading] = useState(false);
    const [account, setAccount] = useState<IAccount | null>(null);

    async function createAccount() {
        try {
            setErros({});
            const schema = yup.object().shape({
                name: yup.string().required('Nome é obrigatório'),
                initialValue: yup.number().required('Valor inicial é obrigatório'),
                typeAccount: yup.string().required('Tipo é obrigatório'),
            });
            schema.validateSync({ name, initialValue, typeAccount }, { abortEarly: false });
            setLoading(true);

            const response = await AccountsSvc.createAccount({
                name,
                urlIcon,
                type: typeAccount as TypeAccount,
                initialValue: FormatNumber.decimalToInteger(initialValue),
            });
            isNewRef.current = false;
            idRef.current = response.id;
            setLoading(false);
            fetchAccount();
            navigate(`/accounts/${response.id}`, {
                replace: true,
            });
        } catch (error) {
            setLoading(false);
            if (error?.name === 'ValidationError') {
                setErros(
                    (error as yup.ValidationError).inner.reduce(
                        (acc: Object, cur: yup.ValidationError) => ({
                            ...acc,
                            [cur.path as string]: cur.errors[0],
                        }),
                        {}
                    )
                );
            } else {
                toast({
                    description: error?.message ?? 'Erro ao tentar salvar conta',
                    duration: 5000,
                    status: 'error',
                });
            }
        }
    }

    async function updateAccount() {
        try {
            if (!account) {
                return;
            }
            setErros({});
            const schema = yup.object().shape({
                name: yup.string().required('Nome é obrigatório'),
                typeAccount: yup.string().required('Tipo é obrigatório'),
                initialValue: yup.number().required('Valor inicial é obrigatório'),
            });
            schema.validateSync({ name, initialValue, typeAccount }, { abortEarly: false });

            setLoading(true);
            const response = await AccountsSvc.updateAccount(account.id, {
                name,
                urlIcon,
                type: typeAccount as TypeAccount,
                initialValue: FormatNumber.decimalToInteger(initialValue),
            });
            isNewRef.current = false;
            idRef.current = response.id;
            setLoading(false);
            fetchAccount();
            toast({
                description: 'Conta salva com sucesso!',
                duration: 1000,
                status: 'success',
            });
        } catch (error) {
            setLoading(false);
            if (error?.name === 'ValidationError') {
                setErros(
                    (error as yup.ValidationError).inner.reduce(
                        (acc: Object, cur: yup.ValidationError) => ({
                            ...acc,
                            [cur.path as string]: cur.errors[0],
                        }),
                        {}
                    )
                );
            } else {
                toast({
                    description: error?.message ?? 'Erro ao tentar salvar conta',
                    duration: 5000,
                    status: 'error',
                });
            }
        }
    }

    const fetchAccount = useCallback(async () => {
        try {
            if (!idRef.current || isNewRef.current || loadingRef.current) {
                return;
            }
            loadingRef.current = true;
            setLoading(true);
            const response = await AccountsSvc.getAccount(idRef.current);
            setAccount(response);
            setName(response.name);
            setUrlIcon(response.urlIcon);
            setInitialValue(FormatNumber.decimalIntegerNumber(response.initialValue, 2));
            setInitialValueText(
                FormatNumber.decimalInteger(FormatNumber.decimalIntegerNumber(response.initialValue, 2), 2)
            );
            setTypeAccount(response.type);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toast({
                description: error?.message,
                duration: 5000,
                status: 'error',
            });
        } finally {
            loadingRef.current = false;
        }
    }, [toast]);

    useEffect(() => {
        fetchAccount();
    }, [fetchAccount]);

    return (
        <Stack>
            <BreadcrumbCustom
                options={[
                    {
                        name: 'Dashboard',
                        route: '/',
                    },
                    {
                        name: 'Contas',
                        route: '/accounts',
                    },
                    {
                        name: isNewRef.current ? 'Nova Conta' : 'Editar Conta',
                        route: '',
                        tooltip: isNewRef.current ? undefined : idRef.current,
                    },
                ]}
            />
            <HStack width="25%" justifyContent="center">
                {loading && <Spinner />}
            </HStack>
            <VStack p={5} alignItems="flex-start">
                <HStack width="25%">
                    <FormControl isRequired isInvalid={!!erros['name']}>
                        <FormLabel>Nome da conta</FormLabel>
                        <Input value={name} onChange={(e) => setName(e.target.value)} />
                        {!!erros['name'] && <FormErrorMessage>{erros['name']}</FormErrorMessage>}
                    </FormControl>
                </HStack>

                <HStack width="25%"></HStack>

                <HStack width="25%">
                    <FormControl isInvalid={!!erros['urlIcon']}>
                        <FormLabel>URL Ícone</FormLabel>
                        <Input value={urlIcon} onChange={(e) => setUrlIcon(e.target.value)} />
                        {!!erros['urlIcon'] && <FormErrorMessage>{erros['urlIcon']}</FormErrorMessage>}
                    </FormControl>
                    <ImageAccount size={55} urlIcon={urlIcon} />
                </HStack>

                <HStack width="25%">
                    <FormControl isRequired isInvalid={!!erros['initialValue']}>
                        <FormLabel>Valor de abertura</FormLabel>
                        <InputGroup>
                            <InputLeftAddon children="R$" />
                            <Input
                                type="text"
                                value={initialValueText}
                                onChange={(e) =>
                                    setInitialValueText(FormatNumber.clearCharNotNumber(e.target.value, true))
                                }
                                onBlur={(e) => {
                                    const value = e.target.value.replaceAll(',', '.');
                                    setInitialValue(parseFloat(parseFloat(value).toFixed(2)));
                                    setInitialValueText(FormatNumber.decimal(parseFloat(value), 2));
                                }}
                            />
                        </InputGroup>
                        {!!erros['initialValue'] && <FormErrorMessage>{erros['initialValue']}</FormErrorMessage>}
                    </FormControl>
                </HStack>

                <HStack width="25%">
                    <FormControl isRequired isInvalid={!!erros['typeAccount']}>
                        <FormLabel>Tipo</FormLabel>
                        <Select value={typeAccount} onChange={(e) => setTypeAccount(e.target.value as TypeAccount)}>
                            <option value=""></option>
                            {TypeAccountUtil.values.map(({ id, description }) => (
                                <option key={id} value={id}>
                                    {description}
                                </option>
                            ))}
                        </Select>
                        {!!erros['typeAccount'] && <FormErrorMessage>{erros['typeAccount']}</FormErrorMessage>}
                    </FormControl>
                </HStack>

                {!!account && (
                    <>
                        <HStack width="25%">
                            <FormControl isReadOnly>
                                <FormLabel>Criado em</FormLabel>
                                <Input value={new Date(account.createdAt).toLocaleString()} />
                            </FormControl>
                        </HStack>
                        {!!account.deletedAt && (
                            <HStack width="25%">
                                <FormControl isReadOnly>
                                    <FormLabel>Deletado em</FormLabel>
                                    <Input value={new Date(account.deletedAt).toLocaleString()} />
                                </FormControl>
                            </HStack>
                        )}
                    </>
                )}

                <HStack paddingTop={3} width="25%">
                    <Button onClick={account ? updateAccount : createAccount} colorScheme="blue">
                        Salvar
                    </Button>
                </HStack>
            </VStack>
        </Stack>
    );
};

export default EditAccountPage;
