import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

function parseToInputDateTime(date: Date | null): string {
    if (!date) {
        return '';
    }

    const ano = new Date(date).getFullYear();
    const mes = String(new Date(date).getMonth() + 1).padStart(2, '0');
    const dia = String(new Date(date).getDate()).padStart(2, '0');
    const hora = String(new Date(date).getHours()).padStart(2, '0');
    const minuto = String(new Date(date).getMinutes()).padStart(2, '0');

    return `${ano}-${mes}-${dia}T${hora}:${minuto}`;
}

function formatDate(data?: Date | null): string {
    if (!data) {
        return '';
    }
    return format(new Date(data), new Date(data).getFullYear() === new Date().getFullYear() ? 'dd/MMM' : 'dd/MMM/yy', {
        locale: ptBR,
    });
}

function formatWithCropDate(data?: Date | null): string {
    if (!data) {
        return '';
    }
    const dataStr = new Date(data).toISOString();
    return `${dataStr.substring(8, 10)}/${dataStr.substring(5, 7)}/${dataStr.substring(0, 4)}`;
}

function formatarDataCompleta(data?: Date | null): string {
    if (!data) {
        return '';
    }
    return format(new Date(data), 'dd/MMM/yyyy', { locale: ptBR });
}

function formatarDataHora(data?: Date | null): string {
    if (!data) {
        return '';
    }
    return format(
        new Date(data),
        new Date(data).getFullYear() === new Date().getFullYear() ? 'dd/MMM HH:mm' : 'dd/MMM/yy HH:mm',
        {
            locale: ptBR,
        }
    );
}

function formatarDataHoraCompleta(data?: Date | null): string {
    if (!data) {
        return '';
    }
    return format(new Date(data), 'dd/MM/yyyy HH:mm:ss', { locale: ptBR });
}

function formatarDataDiaSemana(data?: Date | null): string {
    if (!data) {
        return '';
    }

    const dataFormat = format(
        new Date(data),
        new Date(data).getFullYear() === new Date().getFullYear() ? 'dd/MMM' : 'dd/MMM/yy',
        {
            locale: ptBR,
        }
    );

    return `${diaSemana[new Date(data).getDay()]}, ${dataFormat}`;
}

function obterDiaSemana(data?: Date | null) {
    if (!data) {
        return '';
    }
    return diaSemana[new Date(data).getDay()];
}

const diaSemana = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'];

function formatarDataISO(data?: Date | string | null): string | null {
    if (!data) {
        return null;
    }

    const ano = new Date(data).getFullYear();
    const mes = String(new Date(data).getMonth() + 1).padStart(2, '0');
    const dia = String(new Date(data).getDate()).padStart(2, '0');

    return `${ano}-${mes}-${dia}`;
}

function formatarDataNomeArquivo(data?: Date | null): string {
    if (!data) {
        return '';
    }
    return format(new Date(data), 'yyyyMMddhhmmss', { locale: ptBR });
}

function getNameMonth(mes: string | number) {
    switch (parseInt(String(mes), 10)) {
        case 1:
            return 'jan';
        case 2:
            return 'fev';
        case 3:
            return 'mar';
        case 4:
            return 'abr';
        case 5:
            return 'mai';
        case 6:
            return 'jun';
        case 7:
            return 'jul';
        case 8:
            return 'ago';
        case 9:
            return 'set';
        case 10:
            return 'out';
        case 11:
            return 'nov';
        case 12:
            return 'dez';
    }
}

function getArrayMonths() {
    return [
        { id: 1, name: 'jan' },
        { id: 2, name: 'fev' },
        { id: 3, name: 'mar' },
        { id: 4, name: 'abr' },
        { id: 5, name: 'mai' },
        { id: 6, name: 'jun' },
        { id: 7, name: 'jul' },
        { id: 8, name: 'ago' },
        { id: 9, name: 'set' },
        { id: 10, name: 'out' },
        { id: 11, name: 'nov' },
        { id: 12, name: 'dez' },
    ];
}

export default {
    formatWithCropDate,
    parseToInputDateTime,
    formatDate,
    formatarDataCompleta,
    formatarDataHora,
    formatarDataDiaSemana,
    obterDiaSemana,
    getNameMonth,
    formatarDataISO,
    formatarDataNomeArquivo,
    formatarDataHoraCompleta,
    getArrayMonths,
};
