import * as React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Session from '../utils/session/Session';
import LoginPage from './auth/LoginPage';
import MainPage from './main/MainPage';

export default function Router() {
    return (
        <Routes>
            <Route
                path="/login"
                element={
                    <PublicRoute>
                        <LoginPage />
                    </PublicRoute>
                }
            />
            <Route
                path="*"
                element={
                    <PrivateRoute>
                        <MainPage />
                    </PrivateRoute>
                }
            />
        </Routes>
    );
}

function PrivateRoute({ children }: { children: JSX.Element }) {
    return Session.getToken() ? children : <Navigate to="/login" replace />;
}

function PublicRoute({ children }: { children: JSX.Element }) {
    return !Session.getToken() ? children : <Navigate to="/" replace />;
}
