import {
    Button,
    Divider,
    FormControl,
    FormErrorMessage,
    FormLabel,
    HStack,
    Input,
    InputGroup,
    InputRightElement,
    Spinner,
    useToast,
    VStack,
    Text,
} from '@chakra-ui/react';
import { FC, useEffect, useState, useCallback, useRef } from 'react';
import { Stack } from '@chakra-ui/react';
import * as yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';

import GroupsSvc, { IGroup } from '../../services/GroupsSvc';
import SubGroupsSvc from '../../services/SubGroupsSvc';
import BreadcrumbCustom from '../../components/BreadcrumbCustom';
import { AddIcon, CheckIcon, CloseIcon } from '@chakra-ui/icons';
import Dialog, { IDialogProps } from '../../components/Dialog';

interface ISubGroupEdit {
    id?: string;
    name: string;
    isNew?: boolean;
}

const EditGroupPage: FC = () => {
    const toast = useToast();
    const navigate = useNavigate();
    const { id } = useParams();
    const isNewRef = useRef(id === 'new');
    const idRef = useRef(id);
    const loadingRef = useRef(false);
    const [name, setName] = useState('');
    const [erros, setErros] = useState<any>({});
    const [loading, setLoading] = useState(false);
    const [group, setGroup] = useState<IGroup | null>(null);
    const [subGroups, setSubGroups] = useState<ISubGroupEdit[]>([]);
    const [propDialog, setPropDialog] = useState<IDialogProps | null>(null);

    function addNewSubGroup() {
        const cloneArr = [...subGroups];
        setSubGroups([...cloneArr, { name: '', isNew: true }]);
    }

    function removeSubGroup(item: ISubGroupEdit, index: number) {
        const cloneArr = [...subGroups];
        if (item.isNew) {
            setSubGroups(cloneArr.filter((_, i) => i !== index));
        } else {
            openModalDeleteOrReactivate(item);
        }
    }

    async function createGroup() {
        try {
            setErros({});
            const schema = yup.object().shape({
                name: yup.string().required('Nome é obrigatório'),
            });
            schema.validateSync({ name }, { abortEarly: false });
            setLoading(true);

            const response = await GroupsSvc.createGroup({
                name,
            });
            isNewRef.current = false;
            idRef.current = response.id;
            setLoading(false);
            fetchGroup();
            navigate(`/groups/${response.id}`, {
                replace: true,
            });
        } catch (error) {
            setLoading(false);
            if (error?.name === 'ValidationError') {
                setErros(
                    (error as yup.ValidationError).inner.reduce(
                        (acc: Object, cur: yup.ValidationError) => ({
                            ...acc,
                            [cur.path as string]: cur.errors[0],
                        }),
                        {}
                    )
                );
            } else {
                toast({ description: error?.message ?? 'Erro ao tentar salvar grupo', duration: 5000, status: 'error' });
            }
        }
    }

    async function updateGroup() {
        try {
            if (!group) {
                return;
            }
            setErros({});
            const schema = yup.object().shape({
                name: yup.string().required('Nome é obrigatório'),
            });
            schema.validateSync({ name }, { abortEarly: false });

            setLoading(true);
            const response = await GroupsSvc.updateGroup(group.id, {
                name,
            });
            isNewRef.current = false;
            idRef.current = response.id;
            setLoading(false);
            fetchGroup();
            toast({
                description: 'Grupo salvo com sucesso!',
                duration: 1000,
                status: 'success',
            });
        } catch (error) {
            setLoading(false);
            if (error?.name === 'ValidationError') {
                setErros(
                    (error as yup.ValidationError).inner.reduce(
                        (acc: Object, cur: yup.ValidationError) => ({
                            ...acc,
                            [cur.path as string]: cur.errors[0],
                        }),
                        {}
                    )
                );
            } else {
                toast({
                    description: error?.message ?? 'Erro ao tentar salvar grupo',
                    duration: 5000,
                    status: 'error',
                });
            }
        }
    }

    const fetchGroup = useCallback(async () => {
        try {
            if (!idRef.current || isNewRef.current || loadingRef.current) {
                return;
            }
            loadingRef.current = true;
            setLoading(true);
            const response = await GroupsSvc.getGroup(idRef.current);
            setGroup(response);
            setSubGroups(response.subGroups ?? []);
            setName(response.name);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toast({
                description: error?.message,
                duration: 5000,
                status: 'error',
            });
        } finally {
            loadingRef.current = false;
        }
    }, [toast]);

    const deleteSubGroup = useCallback(
        async (subGroup: ISubGroupEdit) => {
            try {
                const response = await SubGroupsSvc.deleteSubGroup(subGroup.id ?? '');
                toast({
                    description: response?.message,
                    duration: 2000,
                    status: 'success',
                });
                const cloneArr = [...subGroups];
                setSubGroups(cloneArr.filter((it) => it.id !== subGroup.id));
            } catch (error) {
                toast({
                    description: error?.message,
                    duration: 5000,
                    status: 'error',
                });
            }
        },
        [subGroups, toast]
    );

    const openModalDeleteOrReactivate = useCallback(
        (subGroup: ISubGroupEdit) => {
            setPropDialog({
                title: 'Confirmação',
                message: `Deseja deletar o sub-grupo "${subGroup.name}"? ele poderá ser restaurado posteriormente.`,
                onOk: () => deleteSubGroup(subGroup),
                onClose: () => setPropDialog(null),
            });
        },
        [deleteSubGroup]
    );

    useEffect(() => {
        fetchGroup();
    }, [fetchGroup]);

    return (
        <Stack>
            <BreadcrumbCustom
                options={[
                    {
                        name: 'Dashboard',
                        route: '/',
                    },
                    {
                        name: 'Grupos',
                        route: '/groups',
                    },
                    {
                        name: isNewRef.current ? 'Novo Grupo' : 'Editar Grupo',
                        route: '',
                        tooltip: isNewRef.current ? undefined : idRef.current,
                    },
                ]}
            />
            <VStack p={5} alignItems="flex-start">
                <Text fontSize="2xl">Grupo</Text>
                <HStack width="25%">
                    <FormControl isRequired isInvalid={!!erros['name']}>
                        <FormLabel>Nome do grupo</FormLabel>
                        <InputGroup>
                            <Input value={name} onChange={(e) => setName(e.target.value)} />
                            <InputRightElement>
                                {loading ? (
                                    <Spinner size="xs" />
                                ) : (
                                    <Button onClick={group ? updateGroup : createGroup}>
                                        <CheckIcon color="green.500" />
                                    </Button>
                                )}
                            </InputRightElement>
                        </InputGroup>
                        {!!erros['name'] && <FormErrorMessage>{erros['name']}</FormErrorMessage>}
                    </FormControl>
                </HStack>

                {!!group && (
                    <>
                        <HStack width="25%">
                            <FormControl isReadOnly>
                                <FormLabel>Criado em</FormLabel>
                                <Input value={new Date(group.createdAt).toLocaleString()} />
                            </FormControl>
                        </HStack>
                        {!!group.deletedAt && (
                            <HStack width="25%">
                                <FormControl isReadOnly>
                                    <FormLabel>Deletado em</FormLabel>
                                    <Input value={new Date(group.deletedAt).toLocaleString()} />
                                </FormControl>
                            </HStack>
                        )}
                    </>
                )}
                {!isNewRef.current && (
                    <>
                        <HStack width="25%">
                            <Divider marginTop={15} marginBottom={15} />
                        </HStack>
                        <Text fontSize="2xl">Sub-Grupos</Text>
                        <VStack width="100%" alignItems="flex-start">
                            {subGroups.map((it, index) => (
                                <FieldSubGrupoEdit
                                    key={it.id ?? index}
                                    it={it}
                                    index={index}
                                    groupId={group?.id ?? ''}
                                    removeSubGroup={removeSubGroup}
                                    setSubGroups={setSubGroups}
                                />
                            ))}
                            <HStack width="100%">
                                <Button onClick={addNewSubGroup} width="25%">
                                    Adicionar <AddIcon color="white.500" marginLeft={3} />
                                </Button>
                            </HStack>
                        </VStack>
                    </>
                )}
            </VStack>
            {propDialog && <Dialog {...propDialog} />}
        </Stack>
    );
};

interface FieldSubGrupoEditProps {
    it: ISubGroupEdit;
    index: number;
    groupId: string;
    removeSubGroup: (it: ISubGroupEdit, index: number) => void;
    setSubGroups: React.Dispatch<React.SetStateAction<ISubGroupEdit[]>>;
}

function FieldSubGrupoEdit({ it, index, removeSubGroup, groupId, setSubGroups }: FieldSubGrupoEditProps) {
    const toast = useToast();
    const [value, setValue] = useState(it.name ?? '');
    const [errorDesc, setErrorDesc] = useState('');

    const saveOrUpdateSubGroup = useCallback(
        async (subGroup: ISubGroupEdit) => {
            try {
                if (value === '') {
                    return setErrorDesc('Preencha o campo!');
                }
                if (subGroup.isNew) {
                    const response = await SubGroupsSvc.createSubGroup(groupId ?? '', {
                        name: value,
                    });
                    setSubGroups((val) => {
                        const cloneArr = [...val];
                        return cloneArr.map((item, i) => {
                            if (index === i) {
                                return response;
                            } else {
                                return item;
                            }
                        });
                    });
                } else {
                    await SubGroupsSvc.updateSubGroup(subGroup.id ?? '', {
                        name: value,
                    });
                }
                toast({
                    description: 'Operação salva com sucesso!',
                    duration: 2000,
                    status: 'success',
                });
                setErrorDesc('');
            } catch (error) {
                toast({
                    description: error?.message,
                    duration: 5000,
                    status: 'error',
                });
            }
        },
        [groupId, index, setSubGroups, toast, value]
    );

    return (
        <HStack key={it.id ?? index} width="25%">
            <FormControl isRequired isInvalid={!!errorDesc}>
                <InputGroup>
                    <Input value={value} onChange={(e) => setValue(e.target.value)} />
                    <InputRightElement width="6rem">
                        <Button onClick={() => saveOrUpdateSubGroup(it)}>
                            <CheckIcon color="green.500" />
                        </Button>
                        <Button onClick={() => removeSubGroup(it, index)}>
                            <CloseIcon color="red.500" />
                        </Button>
                    </InputRightElement>
                </InputGroup>
                {!!errorDesc && <FormErrorMessage>{errorDesc}</FormErrorMessage>}
            </FormControl>
        </HStack>
    );
}

export default EditGroupPage;
