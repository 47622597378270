function inputKeyMoney(lastValue: number, key: string): number {
    let lastValueString = String(lastValue);
    const validNumberKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    if (validNumberKeys.includes(key)) {
        if (lastValueString.length >= 15) {
            return parseInt(lastValueString);
        }
        if (key === '0' && (lastValueString === '0' || lastValueString === '')) {
            return parseInt(lastValueString === '' ? '0' : lastValueString);
        }
        lastValueString = `${lastValueString}${key}`;
    } else if (key === 'Backspace' || key === 'Delete') {
        lastValueString = lastValueString.substring(0, lastValueString.length - 1);
    }
    return parseInt(lastValueString === '' ? '0' : lastValueString);
}

function formatKeyMoney(value: number): string {
    let val = String(value).padStart(3, '0');
    let integer = val.substring(0, val.length - 2);
    let decimal = val.substring(val.length - 2, val.length);
    integer = integer
        .split('')
        .reverse()
        .map((e, index) => {
            let mod = (index + 1) % 3;
            if (mod === 0 && index + 1 !== integer.length) {
                return `.${e}`;
            }
            return e;
        })
        .reverse()
        .join('');
    val = `${integer},${decimal}`;
    return val;
}

function parseKeyMoneyToNumber(value: string): number {
    let val = value.padStart(3, '0');
    let integer = val.substring(0, val.length - 2);
    let decimal = val.substring(val.length - 2, val.length);
    return parseFloat(`${integer}.${decimal}`);
}

function formatValueOnPaste(value: string = ''): number {
    const validNumberChar = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', ','];
    const valArray = value.split('').filter((c) => validNumberChar.includes(c));
    const decimalChar = valArray[valArray.length - 3];
    const withDecimal = decimalChar === '.' || decimalChar === ',';
    const validNumberNumber = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    const val = valArray.filter((c) => validNumberNumber.includes(c)).join('');
    const valDecimal = withDecimal ? val : `${val}00`;
    return parseInt(valDecimal);
}

export default {
    inputKeyMoney,
    formatKeyMoney,
    parseKeyMoneyToNumber,
    formatValueOnPaste,
};
