import { TypeAccount } from '../../services/AccountsSvc';

const values = [
    {
        id: 'CCOR',
        description: 'Conta Corrente',
    },
    {
        id: 'CCRE',
        description: 'Cartão de Crédito',
    },
    {
        id: 'CMOR',
        description: 'Conta Morta',
    },
];

function description(val: TypeAccount) {
    return values.find(({ id }) => id === val)?.description ?? 'Indefinido';
}

export default {
    description,
    values,
};
