import {
    Badge,
    Button,
    FormControl,
    FormLabel,
    HStack,
    IconButton,
    Input,
    InputGroup,
    InputRightElement,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Select,
    Spinner,
    Tbody,
    Tfoot,
    Tooltip,
    useDisclosure,
    useToast,
} from '@chakra-ui/react';
import { FC, useEffect, useState, useCallback, useRef } from 'react';
import { Text, Stack, Td, Tr, Th, TableContainer, Thead, Table } from '@chakra-ui/react';
import { ArrowForwardIcon, ChevronDownIcon, InfoOutlineIcon, RepeatIcon, SearchIcon } from '@chakra-ui/icons';
import { RiSearch2Line } from 'react-icons/ri';

import FlowsSvc, { IFlow, TypeOperation } from '../../services/FlowsSvc';
import Dialog, { IDialogProps } from '../../components/Dialog';
import FormatNumber from '../../utils/value/FormatNumber';
import BreadcrumbCustom from '../../components/BreadcrumbCustom';
import FormatDate from '../../utils/value/FormatDate';
import EditFlowModal from './EditFlowModal';
import SelectAccount from '../../components/select/SelectAccount';
import SelectGroup from '../../components/select/SelectGroup';
import SelectSubGroup from '../../components/select/SelectSubGroup';
import { IAccount } from '../../services/AccountsSvc';
import { IGroup } from '../../services/GroupsSvc';
import { ISubGroup } from '../../services/SubGroupsSvc';
import ImageAccount from '../../components/ImageAccount';

const FlowsListPage: FC = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();
    const firstRunRef = useRef(true);
    const loadingRef = useRef(false);
    const setTimeoutRef = useRef<any>(null);
    const searchRef = useRef('');
    const typeOperationRef = useRef<TypeOperation | ''>('');
    const totalLoadedRef = useRef(0);
    const totalRef = useRef(0);
    const pageRef = useRef(0);
    const accountIdRef = useRef<string | null>(null);
    const groupIdRef = useRef<string | null>(null);
    const subGroupIdRef = useRef<string | null>(null);
    const [searchText, setSearchText] = useState('');
    const [loading, setLoading] = useState(true);
    const [loadingAction, setLoadingAction] = useState<any>({});
    const [total, setTotal] = useState<number | undefined>(undefined);
    const [flows, setFlows] = useState<IFlow[]>([]);
    const [propDialog, setPropDialog] = useState<IDialogProps | null>(null);
    const [flowEdit, setFlowEdit] = useState<IFlow | null>(null);
    const [accountFilter, setAccountFilter] = useState<IAccount | null>(null);
    const [groupFilter, setGroupFilter] = useState<IGroup | null>(null);
    const [subGroupFilter, setSubGroupFilter] = useState<ISubGroup | null>(null);
    const [typeOperation, setTypeOperation] = useState<TypeOperation | ''>('');

    const fetchFlows = useCallback(
        async (reset: boolean) => {
            try {
                if (
                    loadingRef.current ||
                    (totalLoadedRef.current >= totalRef.current && totalRef.current > 0 && !reset)
                ) {
                    return;
                }

                if (reset) {
                    pageRef.current = 0;
                    totalRef.current = 0;
                    totalLoadedRef.current = 0;
                    setFlows([]);
                }

                loadingRef.current = true;

                setLoading(true);
                const response = await FlowsSvc.getFlows({
                    page: pageRef.current + 1,
                    limit: 20,
                    search: searchRef.current,
                    accountId: accountIdRef.current,
                    groupId: groupIdRef.current,
                    subGroupId: subGroupIdRef.current,
                    type: typeOperationRef.current,
                });
                if (reset) {
                    setFlows(response.result);
                } else {
                    setFlows((val) => {
                        const valCopy = [...val, ...response.result];
                        totalLoadedRef.current = valCopy.length;
                        return valCopy;
                    });
                }
                setTotal(response.total);
                pageRef.current = response.page;
                totalRef.current = response.total;
                setLoading(false);
            } catch (error) {
                setLoading(false);
                toast({
                    description: error?.message,
                    duration: 5000,
                    status: 'error',
                });
            } finally {
                loadingRef.current = false;
            }
        },
        [toast]
    );

    const deleteOrReactivateFlow = useCallback(
        async (flow: IFlow) => {
            try {
                setLoadingAction((val: any) => ({ ...val, [flow.id]: true }));
                const action = flow.deletedAt ? FlowsSvc.reactivateFlow : FlowsSvc.deleteFlow;
                const response = await action(flow.id);
                toast({
                    description: response?.message,
                    duration: 2000,
                    status: 'success',
                });
                fetchFlows(true);
            } catch (error) {
                toast({
                    description: error?.message,
                    duration: 5000,
                    status: 'error',
                });
            } finally {
                setLoadingAction((val: any) => {
                    let copy = { ...val };
                    delete copy[flow.id];
                    return copy;
                });
            }
        },
        [fetchFlows, toast]
    );

    const openModalDeleteOrReactivate = useCallback(
        (flow: IFlow) => {
            setPropDialog({
                title: 'Confirmação',
                message: `Realmente deseja excluir a operação? NÃO será possível restaura-lá.`,
                onOk: () => deleteOrReactivateFlow(flow),
                onClose: () => setPropDialog(null),
            });
        },
        [deleteOrReactivateFlow]
    );

    useEffect(() => {
        function listenerScroll() {
            const totalHeight = document.body.scrollHeight;
            const offset = window.pageYOffset + window.innerHeight;
            if (offset >= totalHeight * 0.9 && !loadingRef.current) {
                fetchFlows(false);
            }
        }
        window.addEventListener('scroll', listenerScroll);
        return () => window.removeEventListener('scroll', listenerScroll);
    }, [fetchFlows]);

    useEffect(() => {
        if (firstRunRef.current) {
            fetchFlows(true);
            firstRunRef.current = false;
        }
    }, [fetchFlows]);

    useEffect(() => {
        sessionStorage.setItem('@controle-financeiro:account', JSON.stringify(accountFilter));
    }, [accountFilter]);

    return (
        <Stack>
            <BreadcrumbCustom
                options={[
                    {
                        name: 'Dashboard',
                        route: '/',
                    },
                    {
                        name: 'Operações',
                        route: '/flows',
                    },
                ]}
            />
            <HStack paddingBottom={5} paddingLeft={5} paddingRight={5} justifyContent="space-between" flexWrap="wrap">
                <HStack>
                    <FormControl>
                        <FormLabel>Conta</FormLabel>
                        <SelectAccount
                            placeholder="Selecione uma conta"
                            value={accountFilter}
                            onChange={(e) => {
                                accountIdRef.current = e?.id ?? null;
                                setAccountFilter(e);
                                fetchFlows(true);
                            }}
                        />
                    </FormControl>
                </HStack>
                <HStack>
                    <FormControl maxW={200}>
                        <FormLabel>Tipo</FormLabel>
                        <Select
                            value={typeOperation}
                            onChange={(e) => {
                                typeOperationRef.current = e.target.value as TypeOperation;
                                setTypeOperation(e.target.value as TypeOperation);
                                fetchFlows(true);
                            }}
                        >
                            <option value=""></option>
                            <option value="O">Saída</option>
                            <option value="I">Entrada</option>
                            <option value="TO">Transferência Saída</option>
                            <option value="TI">Transferência Entrada</option>
                        </Select>
                    </FormControl>
                </HStack>
                <HStack>
                    <FormControl>
                        <FormLabel>Grupo</FormLabel>
                        <SelectGroup
                            placeholder="Selecione um grupo"
                            value={groupFilter}
                            onChange={(e) => {
                                groupIdRef.current = e?.id ?? null;
                                subGroupIdRef.current = null;
                                setSubGroupFilter(null);
                                setGroupFilter(e);
                                fetchFlows(true);
                            }}
                        />
                    </FormControl>
                </HStack>
                <HStack>
                    <FormControl>
                        <FormLabel>Sub-Grupo</FormLabel>
                        <SelectSubGroup
                            placeholder="Selecione um sub-grupo"
                            group={groupFilter}
                            value={subGroupFilter}
                            onChange={(e) => {
                                subGroupIdRef.current = e?.id ?? null;
                                setSubGroupFilter(e);
                                fetchFlows(true);
                            }}
                        />
                    </FormControl>
                </HStack>
                <HStack>
                    <FormControl>
                        <FormLabel>Descrição</FormLabel>
                        <InputGroup size="md">
                            <Input
                                pr="4.5rem"
                                placeholder="Pesquisar..."
                                value={searchText}
                                onChange={(e) => {
                                    clearTimeout(setTimeoutRef.current);
                                    setTimeoutRef.current = setTimeout(() => {
                                        searchRef.current = e.target.value;
                                        fetchFlows(true);
                                    }, 1000);
                                    setSearchText(e.target.value);
                                }}
                            />
                            <InputRightElement width="4.5rem">
                                <Button h="1.75rem" size="sm" onClick={() => fetchFlows(true)}>
                                    <SearchIcon />
                                </Button>
                            </InputRightElement>
                        </InputGroup>
                    </FormControl>
                </HStack>
                <HStack></HStack>

                <HStack justifyContent="space-between">
                    <IconButton aria-label="Search database" icon={<RepeatIcon />} onClick={() => fetchFlows(true)} />
                    <Button
                        onClick={() => {
                            setFlowEdit(null);
                            onOpen();
                        }}
                        colorScheme="blue"
                    >
                        Nova Operação
                    </Button>
                </HStack>
            </HStack>

            <TableContainer paddingLeft={5} paddingRight={5}>
                <Table variant="simple">
                    <Thead>
                        <Tr>
                            <Th>Data Operação</Th>
                            <Th textAlign="center">Conta</Th>
                            <Th textAlign="center">Grupo</Th>
                            <Th textAlign="center">Sub-Grupo</Th>
                            <Th textAlign="center">Tipo</Th>
                            <Th textAlign="center">Valor Operação</Th>
                            <Th textAlign="center">Saldo Anterior</Th>
                            <Th textAlign="center">Saldo Posterior</Th>
                            <Th>Descrição</Th>
                            <Th></Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {flows.map((item) => (
                            <Tr key={item.id}>
                                <Td>{FormatDate.formatDate(new Date(item.flowDate))}</Td>
                                <Td textAlign="center">{getDescAccount(item)}</Td>
                                <Td textAlign="center">{!!item.groupId && item.group?.name}</Td>
                                <Td textAlign="center">{!!item.subGroupId && item.subGroup?.name}</Td>
                                <Td textAlign="center">{descType(item.type)}</Td>
                                <Td textAlign="center">
                                    <Text fontWeight="bold" color={item.value > 0 ? 'green.500' : 'red.400'}>
                                        {FormatNumber.moneyInteger(item.value)}
                                    </Text>
                                </Td>
                                <Td textAlign="center">{FormatNumber.moneyInteger(item.previousBalance)}</Td>
                                <Td textAlign="center">{FormatNumber.moneyInteger(item.nextBalance)}</Td>
                                <Td>
                                    {item?.description.length > 15 ? (
                                        <span>
                                            {item.description.substring(0, 15)}...
                                            <Tooltip label={item.description}>
                                                <InfoOutlineIcon boxSize={4} />
                                            </Tooltip>
                                        </span>
                                    ) : (
                                        item?.description
                                    )}
                                </Td>
                                <Td textAlign="right">
                                    <Menu>
                                        <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                                            Ações
                                            {!!loadingAction[item.id] && <Spinner size="xs" marginLeft={2} />}
                                        </MenuButton>
                                        <MenuList>
                                            <MenuItem
                                                onClick={() =>
                                                    toast({
                                                        description: item.id,
                                                        duration: 5000,
                                                        status: 'info',
                                                    })
                                                }
                                            >
                                                Ver Id
                                            </MenuItem>

                                            {item.type !== 'TI' && (
                                                <>
                                                    <MenuItem
                                                        onClick={() => {
                                                            setFlowEdit(item);
                                                            onOpen();
                                                        }}
                                                    >
                                                        Editar
                                                    </MenuItem>
                                                    <MenuItem onClick={() => openModalDeleteOrReactivate(item)}>
                                                        {item.deletedAt ? 'Restaurar' : 'Deletar'}
                                                    </MenuItem>
                                                </>
                                            )}
                                        </MenuList>
                                    </Menu>
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                    <Tfoot>
                        <Tr>
                            <Th colSpan={10}>
                                {flows.length > 0 ? (
                                    <HStack justifyContent="center" p={5}>
                                        <Text>
                                            {flows.length} de {total} registros
                                        </Text>
                                        {loading && <Spinner />}
                                    </HStack>
                                ) : (
                                    <HStack justifyContent="center" p={5}>
                                        <Text>Nenhuma informação encontrada</Text>
                                        <RiSearch2Line size={15} />
                                    </HStack>
                                )}
                            </Th>
                        </Tr>
                    </Tfoot>
                </Table>
            </TableContainer>
            {propDialog && <Dialog {...propDialog} />}
            {isOpen && <EditFlowModal isOpen={isOpen} onClose={onClose} flow={flowEdit} fetchFlows={fetchFlows} />}
        </Stack>
    );
};

function descType(type: 'I' | 'O' | 'TO' | 'TI') {
    if (type === 'I') {
        return (
            <Badge fontSize="0.9em" colorScheme="green">
                ENTRADA
            </Badge>
        );
    } else if (type === 'O') {
        return (
            <Badge fontSize="0.9em" colorScheme="red">
                SAÍDA
            </Badge>
        );
    } else if (type === 'TO') {
        return (
            <Badge fontSize="0.9em" colorScheme="red">
                TRANSFERÊNCIA
                <br />
                SAÍDA
            </Badge>
        );
    } else if (type === 'TI') {
        return (
            <Badge fontSize="0.9em" colorScheme="green">
                TRANSFERÊNCIA
                <br />
                ENTRADA
            </Badge>
        );
    }
    return '';
}

function getDescAccount(flow: IFlow) {
    if (flow.type === 'TI') {
        return (
            <HStack justifyContent="center">
                <ImageAccount size={30} urlIcon={flow?.sourceAccount?.urlIcon} />
                <Text>{flow?.sourceAccount?.name}</Text> <ArrowForwardIcon /> <Text>{flow?.account?.name}</Text>
                <ImageAccount size={30} urlIcon={flow?.account?.urlIcon} />
            </HStack>
        );
    }
    if (flow.type === 'TO') {
        return (
            <HStack justifyContent="center">
                <ImageAccount size={30} urlIcon={flow?.account?.urlIcon} />
                <Text>{flow?.account?.name}</Text> <ArrowForwardIcon /> <Text>{flow?.targetAccount?.name}</Text>
                <ImageAccount size={30} urlIcon={flow?.targetAccount?.urlIcon} />
            </HStack>
        );
    }
    return (
        <HStack justifyContent="center">
            <ImageAccount size={30} urlIcon={flow?.account?.urlIcon} />
            <Text>{flow?.account?.name}</Text>
        </HStack>
    );
}

export default FlowsListPage;
