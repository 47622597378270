import { FC } from 'react';
import { Avatar, Image } from '@chakra-ui/react';

export interface IBreadcrumbCustomProps {
    urlIcon: string;
    size: number;
}

const ImageAccount: FC<IBreadcrumbCustomProps> = ({ urlIcon, size }) => {
    return !!urlIcon ? <Image boxSize={size} src={urlIcon} referrerPolicy="no-referrer" objectFit="contain" /> : <Avatar boxSize={size} />;
};

export default ImageAccount;
