import Api, { IPaginateRequest, IPaginateResponse } from '../api/Api';
import { IAccount } from './AccountsSvc';
import { IGroup } from './GroupsSvc';
import { ISubGroup } from './SubGroupsSvc';

export type TypeOperation = 'I' | 'O' | 'TO' | 'TI';

export interface IFlow {
    id: string;
    flowDate: string;
    type: TypeOperation;
    value: number;
    previousBalance: number;
    nextBalance: number;
    description: string;
    accountId: string;
    account: IAccount;
    groupId: string;
    group: IGroup;
    subGroupId: string;
    subGroup: ISubGroup;
    targetAccountId: string;
    targetAccount: IAccount;
    sourceAccountId: string;
    sourceAccount: IAccount;
    createdAt: Date;
    createdBy: string;
    updatedAt: Date;
    updatedBy: string;
    deletedAt: Date | null;
}

export interface IGetFlowsRequest extends IPaginateRequest {
    deleted?: boolean;
    accountId?: string | null;
    groupId?: string | null;
    subGroupId?: string | null;
    search?: string | null;
    type?: TypeOperation | '';
}

export interface IGetFlowsResponse extends IPaginateResponse<IFlow> {}

export interface IDeleteFlowResponse {
    message: string;
}

export interface IReactivateFlowResponse {
    message: string;
}

export interface ICreateFlowRequest {
    flowDate: string;
    type: TypeOperation;
    value: number;
    description: string;
    accountId: string;
    groupId?: string;
    subGroupId?: string;
    targetAccountId?: string;
}

export interface ICreateFlowResponse extends IFlow {}

export interface IUpdateFlowRequest {
    flowDate: string;
    type: TypeOperation;
    value: number;
    description: string;
    accountId: string;
    groupId?: string;
    subGroupId?: string;
    targetAccountId?: string;
}

export interface IUpdateFlowResponse extends IFlow {}

async function getFlows(param: IGetFlowsRequest): Promise<IGetFlowsResponse> {
    const response = await Api.get('/v1/flows', param);
    return response.data;
}

async function getFlow(id: string): Promise<IFlow> {
    const response = await Api.get(`/v1/flows/${id}`);
    return response.data;
}

async function createFlow(param: ICreateFlowRequest): Promise<ICreateFlowResponse> {
    const response = await Api.post('/v1/flows', param);
    return response.data;
}

async function updateFlow(id: string, param: IUpdateFlowRequest): Promise<IUpdateFlowResponse> {
    const response = await Api.put(`/v1/flows/${id}`, param);
    return response.data;
}

async function deleteFlow(id: string): Promise<IDeleteFlowResponse> {
    const response = await Api.delete(`/v1/flows/${id}`);
    return response.data;
}

async function reactivateFlow(id: string): Promise<IReactivateFlowResponse> {
    const response = await Api.post(`/v1/flows/reactivate/${id}`);
    return response.data;
}

export default {
    getFlows,
    getFlow,
    createFlow,
    updateFlow,
    deleteFlow,
    reactivateFlow,
};
