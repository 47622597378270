import { Heading, HStack, Select, Skeleton, useToast, VStack } from '@chakra-ui/react';
import { useCallback, useEffect, useRef, useState } from 'react';
import SocketEvent from 'react-socket-event';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import DashboardSvc, { IGetDashboardInsOutsMonthResponse } from '../../services/DashboardSvc';
import FormatDate from '../../utils/value/FormatDate';
import FormatNumber from '../../utils/value/FormatNumber';

interface IDataInsOutsChar {
    name: string;
    ins: number;
    outs: number;
}

interface IDataNetMarginChar {
    name: string;
    value: number;
}

function ChartBarInputVsOutput() {
    const toast = useToast();
    const loadingRef = useRef(false);
    const yearRef = useRef(new Date().getFullYear());
    const [netMargin, setNetMargin] = useState<IDataNetMarginChar[]>([]);
    const [insOuts, setInsOuts] = useState<IDataInsOutsChar[]>([]);
    const [loading, setLoading] = useState(false);
    const [year, setYear] = useState(new Date().getFullYear());

    const fetchData = useCallback(async () => {
        try {
            if (loadingRef.current) {
                return;
            }
            loadingRef.current = true;
            setLoading(true);
            const response = await DashboardSvc.getDashboardInsOutsMonth({
                year: yearRef.current,
            });
            setInsOuts(transformDataInsOuts(response));
            setNetMargin(transformDataNetMargin(response));
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toast({
                description: error?.message,
                duration: 5000,
                status: 'error',
            });
        } finally {
            loadingRef.current = false;
        }
    }, [toast]);

    useEffect(() => {
        fetchData();
        const remove = SocketEvent.on('REFRESH_DASHBOARD', () => fetchData(), 'DASHBOARD_INS_OUTS');
        return remove;
    }, [fetchData]);

    return (
        <VStack padding={15} alignItems="flex-start" width="100%">
            <HStack width="100%" justifyContent="flex-end">
                <Select
                    maxW={100}
                    value={year}
                    onChange={(e) => {
                        const yearNumber = parseInt(e.target.value);
                        yearRef.current = yearNumber;
                        setYear(yearNumber);
                        fetchData();
                    }}
                >
                    {Array.from(Array(10).keys()).map((val) => (
                        <option key={val} value={new Date().getFullYear() - val}>
                            {new Date().getFullYear() - val}
                        </option>
                    ))}
                </Select>
            </HStack>
            <HStack width="100%">
                <VStack width="50%">
                    <Heading as="h2" size="md" marginBottom={3}>
                        Receita x Gastos
                    </Heading>
                    <HStack width="100%">
                        {loading ? (
                            <Skeleton height="400px" width="100%" />
                        ) : (
                            <ResponsiveContainer width="100%" height={400}>
                                <BarChart
                                    width={500}
                                    height={300}
                                    data={insOuts}
                                    margin={{
                                        top: 5,
                                        right: 30,
                                        left: 20,
                                        bottom: 5,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Bar dataKey="ins" fill="#38A169" name="Receitas" />
                                    <Bar dataKey="outs" fill="#F56565" name="Gastos" />
                                </BarChart>
                            </ResponsiveContainer>
                        )}
                    </HStack>
                </VStack>
                <VStack width="50%">
                    <Heading as="h2" size="md" marginBottom={3}>
                        Margem Líquida
                    </Heading>
                    <HStack width="100%">
                        {loading ? (
                            <Skeleton height="400px" width="100%" />
                        ) : (
                            <ResponsiveContainer width="100%" height={400}>
                                <BarChart
                                    width={500}
                                    height={300}
                                    data={netMargin}
                                    margin={{
                                        top: 5,
                                        right: 30,
                                        left: 20,
                                        bottom: 5,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Bar dataKey="value" fill="#2b6cb0" name="Margem Líquida" />
                                </BarChart>
                            </ResponsiveContainer>
                        )}
                    </HStack>
                </VStack>
            </HStack>
        </VStack>
    );
}

function transformDataInsOuts(inputs: IGetDashboardInsOutsMonthResponse[]): IDataInsOutsChar[] {
    const data: IDataInsOutsChar[] = [];
    for (let i = 1; i <= 12; i++) {
        const name = FormatDate.getNameMonth(i) ?? 'error';
        let ins = 0;
        let outs = 0;
        inputs?.forEach((it) => {
            if (i === it.month) {
                ins = FormatNumber.decimalIntegerNumber(it.ins, 2);
                outs = FormatNumber.decimalIntegerNumber(it.outs, 2);
            }
        });
        data.push({
            name,
            ins,
            outs,
        });
    }
    return data;
}

function transformDataNetMargin(inputs: IGetDashboardInsOutsMonthResponse[]): IDataNetMarginChar[] {
    const data: IDataNetMarginChar[] = [];
    for (let i = 1; i <= 12; i++) {
        const name = FormatDate.getNameMonth(i) ?? 'error';
        let value = 0;
        inputs?.forEach((it) => {
            if (i === it.month) {
                value = FormatNumber.decimalIntegerNumber(it.ins - it.outs, 2);
            }
        });
        data.push({
            name,
            value,
        });
    }
    return data;
}

export default ChartBarInputVsOutput;
