import { Box, Flex } from '@chakra-ui/react';
import { Navigate, Route, Routes } from 'react-router-dom';

import HeaderMenu from './HeaderMenu';

import UsersListPage from '../users/UsersListPage';
import DashboardPage from '../dashboard/DashboardPage';
import EditUserPage from '../users/EditUserPage';
import AccountsListPage from '../account/AccountsListPage';
import EditAccountPage from '../account/EditAccountPage';
import GroupsListPage from '../group/GroupsListPage';
import EditGroupPage from '../group/EditGroupPage';
import FlowsListPage from '../flow/FlowsListPage';

export default function MainPage() {
    return (
        <Box>
            <Flex position="fixed" width="100%" zIndex={1000}>
                <HeaderMenu />
            </Flex>
            <Box paddingTop="60px" zIndex={-1}>
                <Routes>
                    <Route path="/dashboard" element={<DashboardPage />} />
                    <Route path="/users" element={<UsersListPage />} />
                    <Route path="/users/:id" element={<EditUserPage />} />
                    <Route path="/accounts" element={<AccountsListPage />} />
                    <Route path="/accounts/:id" element={<EditAccountPage />} />
                    <Route path="/groups" element={<GroupsListPage />} />
                    <Route path="/groups/:id" element={<EditGroupPage />} />
                    <Route path="/flows" element={<FlowsListPage />} />
                    <Route path="/*" element={<Navigate to="/dashboard" replace />} />
                </Routes>
            </Box>
        </Box>
    );
}
