import Api from '../api/Api';
import { TypeAccount } from './AccountsSvc';

export interface IGetDashboardBalanceResponse {
    id: string;
    name: string;
    type: TypeAccount;
    urlIcon: string;
    value: number;
}

export interface IGetDashboardInsOutsMonthRequest {
    year: number;
}

export interface IGetDashboardInsOutsMonthResponse {
    month: number;
    ins: number;
    outs: number;
}

export interface IGetDashboardSubGroupOutsRequest {
    year: number;
    month?: number | null;
}

export interface IGetDashboardSubGroupOutsResponse {
    id: string;
    name: string;
    value: number;
}

export interface IGetDashboardSubGroupInsRequest {
    year: number;
    month?: number | null;
}

export interface IGetDashboardSubGroupInsResponse {
    id: string;
    name: string;
    value: number;
}

async function getDashboardBalance(): Promise<IGetDashboardBalanceResponse[]> {
    const response = await Api.get('/v1/dashboard/balance');
    return response.data;
}

async function getDashboardInsOutsMonth(
    param: IGetDashboardInsOutsMonthRequest
): Promise<IGetDashboardInsOutsMonthResponse[]> {
    const response = await Api.get('/v1/dashboard/insOutsMonth', param);
    return response.data;
}

async function getDashboardSubGroupOuts(
    param: IGetDashboardSubGroupOutsRequest
): Promise<IGetDashboardSubGroupOutsResponse[]> {
    const response = await Api.get('/v1/dashboard/subGroupOuts', param);
    return response.data;
}

async function getDashboardSubGroupIns(
    param: IGetDashboardSubGroupInsRequest
): Promise<IGetDashboardSubGroupInsResponse[]> {
    const response = await Api.get('/v1/dashboard/subGroupIns', param);
    return response.data;
}

export default {
    getDashboardBalance,
    getDashboardInsOutsMonth,
    getDashboardSubGroupOuts,
    getDashboardSubGroupIns,
};
