import axios from 'axios';
import Session from '../utils/session/Session';

const Axios = axios.create({
    baseURL: process.env.REACT_APP_URL_API,
    timeout: 30000,
});

Axios.interceptors.request.use(
    async (config) => {
        const token = Session.getToken();

        if (token) {
            config.headers.authorization = `Bearer ${token}`;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

Axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error?.response?.status === 401) {
            Session.clear();
            window.location.href = '/login';
        }
        return Promise.reject(error);
    }
);

export default Axios;
