import Api, { IPaginateRequest, IPaginateResponse } from '../api/Api';

export type TypeAccount = 'CCOR' | 'CCRE' | 'CMOR';

export interface IAccount {
    id: string;
    name: string;
    initialValue: number;
    type: TypeAccount;
    urlIcon: string;
    createdAt: Date;
    createdBy: string;
    updatedAt: Date;
    updatedBy: string;
    deletedAt: Date | null;
}

export interface IGetAccountsRequest extends IPaginateRequest {
    search?: string | null;
    order?: string | null;
    deleted?: boolean;
}

export interface IGetAccountsResponse extends IPaginateResponse<IAccount> {}

export interface IDeleteAccountResponse {
    message: string;
}

export interface IReactivateAccountResponse {
    message: string;
}

export interface IReprocessBalanceResponse {
    message: string;
}

export interface ICreateAccountRequest {
    name: string;
    initialValue: number;
    type: TypeAccount;
    urlIcon: string;
}

export interface ICreateAccountResponse extends IAccount {}

export interface IUpdateAccountRequest {
    name: string;
    initialValue: number;
    type: TypeAccount;
    urlIcon: string;
}

export interface IUpdateAccountResponse extends IAccount {}

async function getAccounts(param: IGetAccountsRequest): Promise<IGetAccountsResponse> {
    const response = await Api.get('/v1/accounts', param);
    return response.data;
}

async function getAccount(id: string): Promise<IAccount> {
    const response = await Api.get(`/v1/accounts/${id}`);
    return response.data;
}

async function createAccount(param: ICreateAccountRequest): Promise<ICreateAccountResponse> {
    const response = await Api.post('/v1/accounts', param);
    return response.data;
}

async function updateAccount(id: string, param: IUpdateAccountRequest): Promise<IUpdateAccountResponse> {
    const response = await Api.put(`/v1/accounts/${id}`, param);
    return response.data;
}

async function deleteAccount(id: string): Promise<IDeleteAccountResponse> {
    const response = await Api.delete(`/v1/accounts/${id}`);
    return response.data;
}

async function reactivateAccount(id: string): Promise<IReactivateAccountResponse> {
    const response = await Api.post(`/v1/accounts/reactivate/${id}`);
    return response.data;
}

async function reprocessBalance(id: string): Promise<IReprocessBalanceResponse> {
    const response = await Api.post(`/v1/accounts/reprocess/${id}`);
    return response.data;
}

export default {
    getAccounts,
    getAccount,
    createAccount,
    updateAccount,
    deleteAccount,
    reactivateAccount,
    reprocessBalance,
};
