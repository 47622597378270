import Api from '../api/Api';

export interface ISubGroup {
    id: string;
    name: string;
    createdAt: Date;
    createdBy: string;
    updatedAt: Date;
    updatedBy: string;
    deletedAt: Date | null;
}

export interface IGetSubGroupsRequest {
    search?: string | null;
    deleted?: boolean;
    order?: string | null;
}

export interface IGetSubGroupsResponse {
    subGroups: ISubGroup[];
}

export interface IDeleteSubGroupResponse {
    message: string;
}

export interface IReactivateSubGroupResponse {
    message: string;
}

export interface ICreateSubGroupRequest {
    name: string;
}

export interface ICreateSubGroupResponse extends ISubGroup {}

export interface IUpdateSubGroupRequest {
    name: string;
}

export interface IUpdateSubGroupResponse extends ISubGroup {}

async function getSubGroups(groupId: string, param: IGetSubGroupsRequest): Promise<IGetSubGroupsResponse> {
    const response = await Api.get(`/v1/subGroups/${groupId}`, param);
    return response.data;
}

async function createSubGroup(groupId: string, param: ICreateSubGroupRequest): Promise<ICreateSubGroupResponse> {
    const response = await Api.post(`/v1/subGroups/${groupId}`, param);
    return response.data;
}

async function updateSubGroup(id: string, param: IUpdateSubGroupRequest): Promise<IUpdateSubGroupResponse> {
    const response = await Api.put(`/v1/subGroups/${id}`, param);
    return response.data;
}

async function deleteSubGroup(id: string): Promise<IDeleteSubGroupResponse> {
    const response = await Api.delete(`/v1/subGroups/${id}`);
    return response.data;
}

async function reactivateSubGroup(id: string): Promise<IReactivateSubGroupResponse> {
    const response = await Api.post(`/v1/subGroups/reactivate/${id}`);
    return response.data;
}

export default {
    getSubGroups,
    createSubGroup,
    updateSubGroup,
    deleteSubGroup,
    reactivateSubGroup,
};
