import { AxiosRequestConfig, AxiosResponse } from 'axios';
import Axios from './Axios';

export const DEFAULT_PAGE_SIZE = 20;

export interface IPaginateRequest {
    page?: number;
    limit?: number;
}

export interface IPaginateResponse<ResultType> {
    result: ResultType[];
    page: number;
    total: number;
    limit: number;
}

async function request(params: AxiosRequestConfig): Promise<AxiosResponse> {
    try {
        return await Axios(params);
    } catch (error) {
        if (error.code === 'ECONNABORTED') {
            throw new Exception(
                'TIME OUT',
                'Parece que estamos com problemas, a operação está demorando mais que o esperado, tente novamente mais tarde',
                false
            );
        }
        if (error.response) {
            if (error.response?.data?.message) {
                throw new Exception(error.response.status, error.response.data.message, false);
            }
            if (error.response?.data?.messages) {
                const message = error.response.data.messages.join('\n');
                throw new Exception(error.response.status, message, false);
            }
            if (error.response?.data?.error) {
                throw new Exception(error.response.status, error.response.data.error, false);
            }
            if (error.response?.status === 404) {
                throw new Exception(error.response.status, '404 - Serviço não encontrado', false);
            }
            throw new Exception(
                error.response ? error.response.status : 'NETWORK ERROR',
                error.response
                    ? typeof error.response.data !== 'object'
                        ? error.response.data
                        : JSON.stringify(error.response.data)
                    : 'Sem conexão com a internet',
                false
            );
        }
        throw new Exception('NETWORK ERROR', 'Sem conexão com a internet', true);
    }
}

export class Exception extends Error {
    status: string;
    internetError: boolean;

    constructor(status: string, message: string, internetError: boolean) {
        super(message);
        this.status = status;
        this.internetError = internetError;
    }
}

export default {
    get: (url: string, params?: any, timeout?: number, headers?: any, responseType?: any) =>
        request({ method: 'get', url, params, timeout, headers, responseType }),
    post: (url: string, data?: any, timeout?: number, headers?: any, responseType?: any) =>
        request({ method: 'post', url, data, timeout, headers, responseType }),
    put: (url: string, data?: any, timeout?: number, headers?: any, responseType?: any) =>
        request({ method: 'put', url, data, timeout, headers, responseType }),
    delete: (url: string, data?: any, timeout?: number, headers?: any, responseType?: any) =>
        request({ method: 'delete', url, data, timeout, headers, responseType }),
};
