import { Heading, HStack, Select, Skeleton, useToast, VStack } from '@chakra-ui/react';
import { useCallback, useEffect, useRef, useState } from 'react';
import SocketEvent from 'react-socket-event';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import DashboardSvc, { IGetDashboardSubGroupInsResponse } from '../../services/DashboardSvc';
import FormatDate from '../../utils/value/FormatDate';
import FormatNumber from '../../utils/value/FormatNumber';

function ChartBarSubGroupsIns() {
    const toast = useToast();
    const loadingRef = useRef(false);
    const yearRef = useRef(new Date().getFullYear());
    const monthRef = useRef(new Date().getMonth() + 1);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<IGetDashboardSubGroupInsResponse[]>([]);
    const [year, setYear] = useState(new Date().getFullYear());
    const [month, setMonth] = useState(new Date().getMonth() + 1);

    const fetchData = useCallback(async () => {
        try {
            if (loadingRef.current) {
                return;
            }
            loadingRef.current = true;
            setLoading(true);
            const response = await DashboardSvc.getDashboardSubGroupIns({
                year: yearRef.current,
                month: monthRef.current !== 0 ? monthRef.current : null,
            });
            setData(
                response.map((it) => ({
                    ...it,
                    value: FormatNumber.decimalIntegerNumber(it.value, 2),
                }))
            );
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toast({
                description: error?.message,
                duration: 5000,
                status: 'error',
            });
        } finally {
            loadingRef.current = false;
        }
    }, [toast]);

    useEffect(() => {
        fetchData();
        const remove = SocketEvent.on('REFRESH_DASHBOARD', () => fetchData(), 'DASHBOARD_SUBGROUP_INS');
        return remove;
    }, [fetchData]);

    return (
        <VStack padding={15} alignItems="flex-start" width="100%">
            <HStack width="100%" justifyContent="flex-end">
                <Select
                    maxW={100}
                    value={month}
                    onChange={(e) => {
                        const monthNumber = parseInt(e.target.value);
                        monthRef.current = monthNumber;
                        setMonth(monthNumber);
                        fetchData();
                    }}
                >
                    <option value={0}>-</option>
                    {FormatDate.getArrayMonths().map(({ id, name }) => (
                        <option key={id} value={id}>
                            {name}
                        </option>
                    ))}
                </Select>
                <Select
                    maxW={100}
                    value={year}
                    onChange={(e) => {
                        const yearNumber = parseInt(e.target.value);
                        yearRef.current = yearNumber;
                        setYear(yearNumber);
                        fetchData();
                    }}
                >
                    {Array.from(Array(10).keys()).map((val) => (
                        <option key={val} value={new Date().getFullYear() - val}>
                            {new Date().getFullYear() - val}
                        </option>
                    ))}
                </Select>
            </HStack>
            <HStack width="100%">
                <VStack width="100%">
                    <Heading as="h2" size="md" marginBottom={3}>
                        Receita por sub-grupo
                    </Heading>
                    <HStack width="100%">
                        {loading ? (
                            <Skeleton height="400px" width="100%" />
                        ) : (
                            <ResponsiveContainer width="100%" height={400}>
                                <BarChart
                                    width={500}
                                    height={300}
                                    data={data}
                                    margin={{
                                        top: 5,
                                        right: 30,
                                        left: 20,
                                        bottom: 5,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" angle={25} tickMargin={50} height={120} interval={0} />
                                    <YAxis />
                                    <Tooltip />
                                    <Bar dataKey="value" fill="#38A169" name="R$" />
                                </BarChart>
                            </ResponsiveContainer>
                        )}
                    </HStack>
                </VStack>
            </HStack>
        </VStack>
    );
}

export default ChartBarSubGroupsIns;
