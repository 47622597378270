export interface ISession {
    user: {
        id: string;
        name: string;
        email: string;
        googleId: string;
        urlPicture: string;
        verified: boolean;
        createdAt: Date;
        createdBy: string;
        updatedAt: Date;
        updatedBy: string;
        deletedAt: Date | null;
    };
    token: string;
}

function set(session: ISession, lembrar: boolean) {
    clear();
    if (lembrar) {
        window.localStorage.setItem('@session', JSON.stringify(session));
    } else {
        window.sessionStorage.setItem('@session', JSON.stringify(session));
    }
}

function clear() {
    window.localStorage.removeItem('@session');
    window.sessionStorage.removeItem('@session');
}

function get(): ISession | null {
    let token = null;
    let tokenLocal = window.localStorage.getItem('@session');
    let tokenSession = window.sessionStorage.getItem('@session');
    if (tokenLocal) {
        token = JSON.parse(tokenLocal);
    } else if (tokenSession) {
        token = JSON.parse(tokenSession);
    }
    return token ?? null;
}

function getToken(): string {
    const session = get();
    if (session) {
        return session.token;
    } else {
        return '';
    }
}

export default { set, clear, get, getToken };
