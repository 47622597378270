import { Divider, Heading, HStack, IconButton } from '@chakra-ui/react';
import { FC } from 'react';
import { Stack } from '@chakra-ui/react';
import { RepeatIcon } from '@chakra-ui/icons';
import SocketEvent from 'react-socket-event';

import CardBalance from './CardBalance';
import ChartBarInputVsOutput from './ChartBarInputVsOutput';
import ChartBarSubGroupsOuts from './ChartBarSubGroupsOuts';
import ChartBarSubGroupsIns from './ChartBarSubGroupsIns';
import CardInvestment from './CardInvestment';

const DashboardPage: FC = () => {
    function refreshAll() {
        SocketEvent.emit('REFRESH_DASHBOARD');
    }

    return (
        <Stack p={5}>
            <HStack justifyContent="space-between">
                <Heading as="h2" size="lg">
                    Dashboard
                </Heading>
                <IconButton aria-label="Search database" icon={<RepeatIcon />} onClick={refreshAll} />
            </HStack>
            <Divider />
            <CardBalance />
            <Divider />
            <CardInvestment />
            <Divider />
            <ChartBarInputVsOutput />
            <Divider />
            <ChartBarSubGroupsIns />
            <Divider />
            <ChartBarSubGroupsOuts />
        </Stack>
    );
};

export default DashboardPage;
