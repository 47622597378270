function moneyInteger(value: number) {
    if (value !== undefined) {
        if (value !== null) {
            if (String(value) !== '') {
                let value2 = String(value);
                if (value2.length === 1) {
                    value2 = `0.0${value2}`;
                } else if (value2.length === 2) {
                    value2 = `0.${value2}`;
                } else {
                    value2 = `${value2.substring(0, value2.length - 2)}.${value2.substring(
                        value2.length - 2,
                        value2.length
                    )}`;
                }
                return parseFloat(parseFloat(value2).toFixed(2)).toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                });
            }
        }
    }
    return '';
}

function money(value: number) {
    if (value !== undefined) {
        if (value !== null) {
            if (String(value) !== '') {
                let value2 = String(value);
                return parseFloat(parseFloat(value2).toFixed(2)).toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                });
            }
        }
    }
    return '';
}

function decimalInteger(value: number, precision: number) {
    if (value !== undefined) {
        if (value !== null) {
            if (String(value) !== '') {
                let val = parseFloat(String(value)).toFixed(precision).replace('.', ',');
                return val === 'NaN' ? '' : val;
            }
        }
    }
    return '';
}

function decimalIntegerNumber(value: number, precision: number): number {
    if (value !== undefined) {
        if (value !== null) {
            if (String(value) !== '') {
                let value2 = String(value);
                if (value2.length === 1) {
                    value2 = `0.0${value2}`;
                } else if (value2.length === 2) {
                    value2 = `0.${value2}`;
                } else {
                    value2 = `${value2.substring(0, value2.length - 2)}.${value2.substring(
                        value2.length - 2,
                        value2.length
                    )}`;
                }
                let val = parseFloat(String(value2)).toFixed(precision);
                return val === 'NaN' ? 0 : parseFloat(val);
            }
        }
    }
    return 0;
}

function decimal(value: number, precision: number) {
    if (value !== undefined) {
        if (value !== null) {
            if (String(value) !== '') {
                let val = parseFloat(String(value)).toFixed(precision).replace('.', ',');
                return val === 'NaN' ? '' : val;
            }
        }
    }
    return '';
}

function clearCharNotNumber(value: string, negative?: boolean) {
    const validChar = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', ',', '.'];
    if (negative) {
        validChar.push('-');
    }
    return value.split('').reduce((prev, cur) => {
        const exist = validChar.some((c) => cur === c);
        return exist ? `${prev}${cur}` : prev;
    }, '');
}

function decimalToInteger(value: number): number {
    let valueFormat = parseFloat(String(value)).toFixed(2);
    return parseInt(valueFormat.replaceAll('.', ''));
}

export default {
    moneyInteger,
    decimalInteger,
    decimalIntegerNumber,
    decimal,
    clearCharNotNumber,
    decimalToInteger,
    money,
};
